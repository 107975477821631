import React, { useRef, useState, useEffect, ChangeEvent } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { EditorProps } from "../../models/editor.models";
import Modal from "../coreui/Modal";
import { getMediaType } from "../../services/point.service";
import { MEDIA_TYPES } from "../../constants/commonConstants";
import Select from "react-select";
import { BASE_URL, TINY_MCE_API_KEY } from "../../app.config";
import { Editor as TinyMCEEditor } from "tinymce";
import { VideoData, VideoSelectedData } from "../../models/staticmenu.models";
import { CFormInput, CFormLabel } from "@coreui/react";
interface Video {
  title: string;
  media_id: string;
}
const EditorComponent: React.FC<EditorProps> = ({
  initialContent,
  updateContent,
  indexKey,
}: EditorProps) => {
  const [allVideo, setAllVideo] = useState<Video[]>([]);
  const [buttonName, setButtonName] = useState<string>("");
  const [content, setContent] = useState<string | undefined>();
  const editorRef = useRef<TinyMCEEditor | null>(null);
  const [inputError, setInputError] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectError, setSelectError] = useState<string | null>(null);
  const [selected, setSelected] = useState<VideoSelectedData | null>(null);
  const [selectOptions, setSelectOptions] = useState<
    { label: string; value: string }[]
  >([]);

  useEffect(() => {
    fetchAllMediaType();
  }, []);

  useEffect(() => {
    if (!content && content !== "") setContent(initialContent ?? "");
  }, [initialContent]);

  const addButton = () => {
    if (buttonName !== "" && selected !== null) {
      setOpenModal(false);
      (editorRef.current as TinyMCEEditor).insertContent(`
      <button class="mp-channel-button"  data-id="${
        selected?.media_id ?? ""
      }" data-video-url="${selected?.file_name ?? ""}" ">
      ${buttonName ?? ""}
      </button>
    `);
    } else {
      setInputError(
        buttonName.trim() === "" ? "Button Name is required" : null
      );
      setSelectError(selected === null ? "Please select an video" : null);
    }
  };

  const fetchAllMediaType = async () => {
    const [video] = await Promise.all([getMediaType("all", MEDIA_TYPES.VIDEO)]);
    setAllVideo(video.data);
    const options =
      video.data &&
      video.data.length > 0 &&
      video.data.map((video: VideoData) => ({
        label: video?.title,
        value: video?.media_id,
        file_name: video?.file_name,
      }));
    setSelectOptions(options);
  };

  const handleEditorChange = (editor: TinyMCEEditor) => {
    const contentString = editor.getContent({ format: "html" });
    if (updateContent) {
      updateContent(contentString, indexKey as string);
    }
  };

  const handleEditorSetup = (editor: TinyMCEEditor) => {
    editorRef.current = editor;

    editor.ui.registry.addButton("myVideoButton", {
      text: "Add Video Button",
      onAction: () => {
        setOpenModal(true);
      },
    });
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setButtonName(e.target.value as string);
    setInputError(null);
  };

  const handleSelectChange = (
    selectedOption: VideoSelectedData | null
  ): void => {
    setSelected(selectedOption);
    setSelectError(null);
  };

  const activeMediaProps = { title: "Select video" };

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        size="xl"
        onSave={() => {
          addButton();
        }}
        activeMediaProps={activeMediaProps}
        saveButtonLabel={"Add button"}
      >
        <CFormInput
          type="text"
          id="nf-name"
          name="nf-name"
          placeholder="Button Name"
          autoComplete="name"
          value={buttonName}
          onChange={handleInputChange}
        />
        {inputError && <p className="error-message">{inputError}</p>}
        <div className="mp-select">
          <Select
            options={selectOptions}
            value={selected}
            placeholder="Select video"
            onChange={handleSelectChange}
          />
          {selectError && <p className="error-message">{selectError}</p>}
        </div>
      </Modal>
      <Editor
        apiKey={TINY_MCE_API_KEY} // Replace with your TinyMCE API key
        initialValue={content} // Set the initial HTML content
        init={{
          height: 500,
          menubar: true,
          branding: false,
          convert_urls: false,
          plugins:
            " preview  searchreplace autolink directionality  visualblocks visualchars fullscreen image link media codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists code wordcount  linkchecker help table image",
          toolbar1:
            "formatselect | bold italic strikethrough forecolor backcolor | link | myVideoButton | table | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat | image",
          table_default_attributes: {
            class: "table",
          },
          image_advtab: true,
          content_css: [
            "//fonts.googleapis.com/css?family=Lato:300,300i,400,400i",
          ],
          content_style: `
          button.mp-channel-button {
            color: #fff;
            border: 2px solid #d74100;
            background-color: #d74100;
            text-decoration: none;
            padding: 3px 7px;
            margin-right: 15px;
            border-radius: 3px;
          }
        `,
        }}
        onInit={(evt, editor) => handleEditorSetup(editor)}
        onChange={(evt, editor) => handleEditorChange(editor)}
      />
    </>
  );
};

export default EditorComponent;
