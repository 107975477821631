import {
  CButton,
  CFormSelect,
  CImage,
  CInputGroup,
  CModal,
  CModalBody,
  CPopover,
  CToast,
  CToastBody,
  CToastHeader,
  CToaster,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import ImageMapper from "react-img-mapper";
import Modal from "../coreui/Modal";
import Select from "react-select";
import { debounce, uniqueId } from "lodash";
import ConfirmModal from "../shared/ConfirmModal/ConfirmModal";
import {
  ChannelPoints,
  MediaObject,
  PointObject,
  PointObjectProps,
} from "../../models/channel.model";
import {
  BodyAreaMapProps,
  BodyPoint,
  InteractiveModalImageProps,
  Point,
  PointInteractiveImagePoints,
  PointPayload,
} from "../../models/bodyArea.model";
import { POINT_CONSTANTS } from "../../constants/bodyAreaConstants";
import { getChannelPoints } from "../../services/point.service";
import API_SERVICES from "../../services";
import handleResponse from "../../services/apiConfig/api.response.service";
import commonService from "../../services/common.service";
import { CONSTANTS } from "../../constants";
const InteractiveImage = ({
  interactiveImage,
  interactiveImagePoints,
  savePoint,
  loaderProps,
  updateFlag,
  imageProps,
}: InteractiveModalImageProps) => {
  const [allChannels, setAllChannels] = useState<PointObject[]>([]);
  const [calcValue, setCalcValue] = useState<number>(
    POINT_CONSTANTS.DEFAULT_CALC_VALUE
  );
  const [confirmModal, setConfirmModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [interActivePointId, setInterActivePointId] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [newPoint, setNewPoint] = useState<PointPayload[]>([]);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [points, setPoints] = useState<PointInteractiveImagePoints[]>(
    interactiveImagePoints ?? []
  );
  const [pointsFlag, setPointsFlag] = useState(true);
  const [popoverIndex, setPopoverIndex] = useState<number>();
  const [selectValue, setSelectValue] = useState<number | string>();
  const [size, setSize] = useState<number>(POINT_CONSTANTS.LARGE_SCREEN);
  useEffect(() => {
    interactiveImagePoints && setPoints(interactiveImagePoints);
  }, [interactiveImagePoints, interactiveImage]);

  useEffect(() => {
    getPoints();
    window.addEventListener("resize", debouncedHandleResize);
    debouncedHandleResize();
    return () => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  const addPoint = () => {
    if (selectValue && selectValue !== "") {
      newPoint[0].point_id = selectValue as number;
      newPoint[0].point_media_id = interactiveImage?.point_media_id as number;
      setIsLoading(true);
      imageProps?.addPoint && imageProps.addPoint(newPoint);
      setOpenModal(false);
      setErrorMessage("");
      setShowToast(true);
    } else {
      setErrorMessage("Please select point");
    }
  };

  const debouncedHandleResize = debounce(() => {
    setSize(
      window.innerWidth >= POINT_CONSTANTS.LARGE_SCREEN
        ? POINT_CONSTANTS.LARGE_SCREEN
        : POINT_CONSTANTS.SMALL_SCREEN
    );
    setCalcValue(
      window.innerWidth >= POINT_CONSTANTS.LARGE_SCREEN
        ? POINT_CONSTANTS.DEFAULT_CALC_VALUE
        : POINT_CONSTANTS.SMALL_SCREEN / POINT_CONSTANTS.LARGE_SCREEN
    );
  });

  const getPoints = async () => {
    const ChannelPoints = await getChannelPoints();
    const array: PointObject[] = [];
    ChannelPoints.data.map((channel: ChannelPoints) => {
      array.push(...channel.points);
    });
    setAllChannels(array);
  };

  const handleImageClick = (event: React.MouseEvent<HTMLImageElement>) => {
    setOpenModal(true);
    setSelectValue("");
    const point: BodyPoint = {
      pos_x: event.nativeEvent.offsetX / calcValue - 7,
      pos_y: event.nativeEvent.offsetY / calcValue - 7,
      target: 0,
    };
    setNewPoint([point]);
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setErrorMessage("");
    setSelectValue(event.target.value);
  };

  const imageMapperData = {
    src: interactiveImage?.file_name,
    map: {
      name: "my-image",
      areas: [],
    },
  };
  const activeMediaProps = {
    title: POINT_CONSTANTS.ADD_POINT,
  };
  return (
    <>
      <CToaster>
        {showToast && (
          <CToast visible={showToast} autohide={true} color="warning">
            <CToastBody>Point added successfully</CToastBody>
          </CToast>
        )}
      </CToaster>

      <ConfirmModal
        title=" Are you sure you want to delete point"
        confirmButtonText="Confirm"
        cancelButtonText="Cancel"
        onConfirm={() => {
          setConfirmModal(false);
          if (interActivePointId) {
            imageProps?.deletePoint &&
              imageProps?.deletePoint(interActivePointId);
            setPopoverIndex(undefined);
          }
        }}
        onCancel={() => {
          setConfirmModal(false);
        }}
        isShow={confirmModal}
      />
      <Modal
        className="modal-static"
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setErrorMessage("");
        }}
        activeMediaProps={activeMediaProps}
        onSave={() => {
          addPoint();
        }}
        saveButtonLabel={"Save Point"}
      >
        <CInputGroup>
          <CFormSelect id="mediaSelect" onChange={handleOnChange}>
            <option value="" selected disabled>
              Please select a point
            </option>
            {allChannels?.map((point: PointObject) => (
              <option key={point?.id} value={point?.id}>
                {point?.name}
              </option>
            ))}
          </CFormSelect>
        </CInputGroup>
        <div className="mp-error">{errorMessage}</div>
      </Modal>
      <div className="d-flex justify-content-center">
        <div>
          <div className="mp-point">
            <ImageMapper
              src={imageMapperData.src ?? "s"}
              map={imageMapperData.map}
              width={size}
              onImageClick={event => {
                handleImageClick(event);
              }}
              onLoad={() => {
                loaderProps && loaderProps.updateLoader();
              }}
            />
            {!loaderProps?.isLoading &&
              pointsFlag &&
              points &&
              points.map((point: PointObjectProps, index: number) => (
                <CPopover
                  key={point.slug}
                  className="mp-popover"
                  content={
                    <div>
                      {" "}
                      <div
                        className="mp-close mp-cursor-pointer"
                        onClick={() => setPopoverIndex(undefined)}
                      >
                        x
                      </div>
                      <div className="mp-popover-body d-flex justify-content-between">
                        <div>
                          {" "}
                          {point.slug + " " + (point.chinese_name ?? "")}
                          <h3>
                            {point.english_title +
                              " " +
                              point.chinese_character}
                          </h3>
                        </div>
                        <div>
                          <CImage src={point.chinese_text_image} alt="" />
                        </div>
                      </div>
                      <CButton
                        onClick={() => {
                          setInterActivePointId(
                            point?.id ?? point?.intractive_point_id
                          );
                          setConfirmModal(true);
                        }}
                      >
                        Delete Point
                      </CButton>
                    </div>
                  }
                  placement="auto"
                  trigger="focus"
                  visible={index === popoverIndex}
                >
                  <div
                    onClick={() => {
                      setPopoverIndex(index);
                    }}
                    className="mp-point-position"
                    style={{
                      left: (point.pos_x as number) * calcValue,
                      top: (point.pos_y as number) * calcValue,
                      width:
                        size >= POINT_CONSTANTS.LARGE_SCREEN ? "14px" : "7px",
                      height:
                        size >= POINT_CONSTANTS.LARGE_SCREEN ? "14px" : "7px",
                    }}
                  />
                </CPopover>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default InteractiveImage;
