import React, { useState } from "react";
import {
  CFormInput,
  CFormLabel,
  CInputGroup,
  CInputGroupText,
} from "@coreui/react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { PasswordProps } from "../../models/user.model";

const ChangePasswordForm: React.FC<PasswordProps> = ({
  label,
  value,
  onChange,
  onBlur,
}) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };

  return (
    <div className="mb-3">
      <CFormLabel htmlFor={`input${label}`}>{label}</CFormLabel>
      <CInputGroup>
        <CFormInput
          type={showPassword ? "text" : "password"}
          id={`input${label}`}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
        <CInputGroupText onClick={handleTogglePasswordVisibility}>
          {showPassword ? <FaEyeSlash /> : <FaEye />}
        </CInputGroupText>
      </CInputGroup>
    </div>
  );
};

export default ChangePasswordForm;
