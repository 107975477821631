import React, { useEffect } from "react";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from "@coreui/react";
import { ModalProps } from "../../models/channel.model";

const Modal: React.FC<ModalProps> = ({
  className,
  children,
  activeMediaProps,
  onClose,
  showSave = true,
  onSave,
  deleteButtonLabel,
  closeButtonLabel = "Close",
  closeButtonColor = "secondary",
  saveButtonLabel = "Save changes",
  saveButtonColor = "primary",
  open = false,
  size = "lg",
}) => {
  return (
    <CModal
      className={className}
      visible={open}
      onClose={onClose}
      backdrop="static"
      size={size}
      scrollable
    >
      <CModalHeader>
        <CModalTitle>{activeMediaProps?.title}</CModalTitle>
      </CModalHeader>
      <CModalBody>{children ? children : children}</CModalBody>
      <CModalFooter>
        <CButton color={closeButtonColor} onClick={onClose}>
          {closeButtonLabel}
        </CButton>
        {showSave && (
          <CButton color={saveButtonColor} onClick={onSave}>
            {deleteButtonLabel ? deleteButtonLabel : saveButtonLabel}
          </CButton>
        )}
      </CModalFooter>
    </CModal>
  );
};

export default Modal;
