export const COMMON_CONSTANTS = {
  EMPTY: "",
  FIVE_MB: 1048576 * 5,
  INITIAL_VALUE: 0,
  LANGUAGE: "en",
  MEDIA_LENGTH: 0,
  NULL: null,
  PREVIOUS_PAGE: -1,
  SUCCESS_STATUS: {
    ERROR: "error",
    SUCCESS: "success",
  },
  VIDEO_PERMISSION_ALL: 3,
  VIDEO_PERMISSION_LITE: 1,
  VIDEO_PERMISSION_STANDARD: 2,
};

export const POINT_CONSTANTS = {
  CHINESE_IMAGE: "chinese_image",
  POINT_IMAGE: "image",
};

export enum Languages {
  DE = "de",
  EN = "en",
  ES = "es",
  RU = "ru",
}

export enum MEDIA_GROUP {
  BODY_AREA = "body_area",
  CHANNEL = "channel",
  CHINESE_IMAGE = "chinese_image",
  POINT = "point",
}

export enum MEDIA_TYPES {
  ASSOCIATED_VIDEO = "associated_videos",
  AUDIO = "audio",
  CHINESE_IMAGE = "chinese_image",
  IMAGE = "image",
  VIDEO = "video",
}

export enum MEDIA_TYPES_CHANNELS {
  VIDEO = "video",
}

export enum MEDIA_VALUE {
  AUDIO = "audio/*",
  IMAGE = "image/*",
  VIDEO = "video/*",
}
export const RELATIVE_VIDEO = {
  PERMISSION_TYPE: "All",
};
