const API_URL = {
  BODY_AREA: {
    GET_ALL_BODY_AREA: "/api/admin/get-all-bodyarea",
    GET_BODY_POINT: "/api/admin/bodyarea/",
    REMOVE_BODY_AREA: "/api/admin/intractive-point-delete",
    SAVE_BODY_AREA: "/api/admin/save-multilingual-bodyarea-details",
  },

  CHANNEL: {
    GET_CHANNEL_MENU_PAGE: "/api/admin/get-multilingual-channel-menu/",
    GET_CHANNEL_MENU: "/api/admin/get-admin-channel-menus",
    GET_CHANNEL_SUBMENU: "/api/admin/get-multilingual-channel-submenu/",
    GET_MEDIA_TYPE_CHANNEL: "/api/admin/get-media/",
    SAVE_CHANNEL_DETAILS: "/api/admin/save-multilingual-channel-page-details",
    SAVE_CHANNEL_MENU: "/api/admin/save-multilingual-channel-menu",
  },

  MENUS: {
    STATIC_MENU_DETAILS: "/api/admin/save-static-menu-details",
    STATIC_MENU_PAGE: "/api/admin/admin-static-menu/",
    STATIC_MENUS: "/api/admin/admin-static-menus",
  },

  MEDIA: {
    DELETE_FILE: "/api/admin/multimedia-delete",
    GET_ALL_MEDIA_TYPE: "/api/admin/get-media/",
    SAVE_FILE_UPLOAD: "/api/admin/multimedia/fileupload",
    UPDATE_FILE: "/api/admin/multimedia/fileupdate",
  },

  POINT: {
    GET_CHANNEL_POINT_PAGE: "/api/admin/get-multilingual-channel-point/",
    GET_CHANNEL_POINT: "/api/admin/get-channel-points",
    GET_MEDIA_TYPE: "/api/admin/get-media/",
    SAVE_CHANNEL_POINT: "/api/admin/save-multilingual-channel-point-details",
    SAVE_POINT_AREA: "/api/admin/save-interactive-point-details",
    REMOVE_POINT_AREA: "/api/admin/delete-interactive-point",
  },

  USER: {
    ADMIN_GET_ALL_USERS: "/api/admin/get-all-users",
    ADMIN_GET_SUBSCRIBE_LIST: "/api/admin/get-subscription-plans",
    ADMIN_GET_USER_DETAILS: "/api/admin/get-user-details",
    ADMIN_LOGIN: "/api/admin/login",
    ADMIN_LOGOUT: "/api/admin/logout",
    CHANGE_PASSWORD: "/api/admin/reset-password",
    CHANGE_SUBSCRIBE: "/api/admin/change-subscription-plan",
  },
};

export default API_URL;
