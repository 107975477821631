import React, { useEffect, useState } from "react";
import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CAlert,
  CButton,
  CListGroup,
  CListGroupItem,
  CSpinner,
} from "@coreui/react";

import { getStaticMenu } from "../../services/apiConfig/menu.service";
import { useNavigate } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import { cilPencil } from "@coreui/icons";
import { MenuItem } from "../../models/staticmenu.models";
import PageLoader from "../../components/PageLoader/PageLoader";

const StaticMenuList: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [staticMenu, setStaticMenuList] = useState<MenuItem[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    handleGetStaticMenu();
  }, []);

  const handelOptions = (itemId: number) => {
    navigate("/admin/edit-static-title/" + itemId);
  };

  const handleEdit = (itemId: number) => {
    navigate("/admin/edit-static-page/" + itemId);
  };

  const handleGetStaticMenu = async () => {
    const menu = await getStaticMenu();
    setStaticMenuList(menu?.data);
    setLoading(false);
  };

  const renderChildItems = (children: MenuItem[] | undefined) => {
    if (children && children.length > 0) {
      return (
        <CListGroup>
          {children.map(child => (
            <CListGroupItem key={child.id}>
              <span>{child.name}</span>
              <CButton color="link" onClick={() => handleEdit(child.id)}>
                <CIcon icon={cilPencil} />
              </CButton>
              {renderChildItems(child.children)}
            </CListGroupItem>
          ))}
        </CListGroup>
      );
    }
    return null;
  };
  const navigate = useNavigate();
  const filterMenuItems = (item: MenuItem) => {
    if (item.name.toLowerCase().includes(searchTerm.trim().toLowerCase())) {
      return true;
    }
    if (item.children) {
      return item.children.some(filterMenuItems);
    }
    return false;
  };

  const filteredMenu = staticMenu?.filter(filterMenuItems) || [];
  const noResultsFound = !loading && filteredMenu.length === 0;
  return (
    <>
      <div className="d-flex justify-content-end mb-2">
        <input
          className="d-flex mb-2 "
          type="text"
          placeholder="Search menu..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
      </div>
      <PageLoader isLoading={loading} />
      {noResultsFound && (
        <CAlert color="info" className="text-center mb-3">
          No results found.
        </CAlert>
      )}
      <CAccordion>
        {filteredMenu.map(data => (
          <CAccordionItem key={data.id}>
            <CAccordionHeader>
              {data.name}
              <CButton color="link" onClick={() => handelOptions(data.id)}>
                <CIcon icon={cilPencil} />
              </CButton>
            </CAccordionHeader>
            <CAccordionBody>{renderChildItems(data.children)}</CAccordionBody>
          </CAccordionItem>
        ))}
      </CAccordion>
    </>
  );
};

export default StaticMenuList;
