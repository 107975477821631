import React, { useEffect, useState } from "react";
import {
  changeSubscribe,
  getAdminSubscribe,
  getAdminUsers,
} from "../../services/user.service";
import { CButton, CFormCheck } from "@coreui/react";
import { cilHandPointUp } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import UserDetail from "./UserDetail";
import UserTable from "../../components/user/UserList/UserList";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/coreui/Modal";
import API_SERVICES from "../../services";
import handleResponse from "../../services/apiConfig/api.response.service";
import commonService from "../../services/common.service";
import {
  CellRendererParams,
  SubscribeListProps,
  UpdateSubscribeProps,
  User,
} from "../../models/user.model";
import { USER_CONSTANTS } from "../../constants/userConstants";
import PageLoader from "../../components/PageLoader/PageLoader";

const Users: React.FC = () => {
  const [adminUsers, setAdminUsers] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [planId, setPlanId] = useState<number>();
  const [subscribe, setSubscribe] = useState([]);
  const [subscribeVisible, setSubscribeVisible] = useState(false);
  const [userId, setUserId] = useState("");
  const [visible, setIsVisible] = useState(false);

  useEffect(() => {
    handlegetAdminUser();
    fetchSubscribe();
  }, []);
  const columnDefs = [
    {
      headerName: "Name",
      field: "first_name",
      sortable: true,
      suppressMenu: true,
      cellRenderer: (params: any) => (
        <CButton color="link" onClick={() => navigateUserDetail(params)}>
          {params.value}
        </CButton>
      ),
    },
    {
      headerName: "Last Name",
      field: "last_name",
      sortable: true,
      suppressMenu: true,
    },
    {
      headerName: "Subscription",
      field: "plan_purchased",
      sortable: true,
      suppressMenu: true,
      cellRenderer: (params: CellRendererParams) => (
        <>
          <span
            className={
              "badge mp-cursor-pointer" +
              (params.data.plan_purchased_id === USER_CONSTANTS.PRO_ID
                ? " bg-success"
                : " bg-secondary")
            }
          >
            {getValueById(params.data.plan_purchased_id as number)}
          </span>
          <CIcon
            className="mp-cursor-pointer"
            icon={cilHandPointUp}
            onClick={() => navigateSubscriptionDetail(params)}
          />
        </>
      ),
    },
    {
      headerName: "Email",
      field: "email",
      sortable: true,
      suppressMenu: true,
    },
    {
      headerName: "Actions",
      sortable: false,
      suppressFilter: true,
      suppressMenu: true,
      cellRenderer: (params: any) => (
        <CButton onClick={() => navigateUserDetail(params)}>
          <CIcon icon={cilHandPointUp} />
        </CButton>
      ),
    },
  ];

  const fetchSubscribe = async () => {
    const subscribeList = await getAdminSubscribe();
    subscribeList?.data && setSubscribe(subscribeList?.data);
  };

  function getValueById(id: number) {
    const foundItem = subscribe.find(
      (item: SubscribeListProps) => item.id === id
    );

    if (foundItem) {
      return (foundItem as SubscribeListProps).name;
    } else {
      return null; // Return null or handle the case when no matching id is found
    }
  }
  const handlegetAdminUser = async () => {
    setLoading(true);
    const users = await getAdminUsers();
    const filteredUsers = users?.data.filter(
      (user: User) => user.plan_purchased_id !== null
    );
    users?.data && setAdminUsers(filteredUsers);
    setLoading(false);
  };

  const navigateSubscriptionDetail = (params: CellRendererParams) => {
    const { data } = params;
    setSubscribeVisible(true);
    data?.uuid && setUserId(data?.uuid);
    data?.uuid && setPlanId(data?.plan_purchased_id);
  };

  const navigateUserDetail = (params: any) => {
    const { data } = params;
    setIsVisible(true);
    data?.uuid && setUserId(data?.uuid);
  };
  const updateSubscribe = async () => {
    const data: UpdateSubscribeProps = {
      plan_id: planId as number,
      uuid: userId as string,
    };
    API_SERVICES.USER.CHANGE_SUBSCRIBE(data)
      .then(response => {
        const responseData: any = handleResponse(response);

        if (responseData.ok) {
          commonService.toastService(
            "Subscription updated successfully",
            "success"
          );
          handlegetAdminUser();
          setSubscribeVisible(false);
        } else {
          commonService.toastService("Unable to change subscription", "error");
        }
      })
      .catch(error => {
        console.error("Error changing subscription:", error);
        commonService.toastService("error", "error");
      });
  };
  const activeMediaProps = {
    title: "Update Subscription",
  };

  return (
    <>
      {loading ? (
        <PageLoader isLoading={loading} />
      ) : (
        <>
          <Modal
            activeMediaProps={activeMediaProps}
            open={subscribeVisible}
            onClose={() => {
              setSubscribeVisible(false);
            }}
            className={"modal-static"}
            onSave={() => {
              updateSubscribe();
            }}
            saveButtonLabel={"Update Subscription"}
          >
            {subscribe.map(
              (item: SubscribeListProps, index: number) =>
                item.id !== USER_CONSTANTS.TRAIL_ID && (
                  <CFormCheck
                    key={index}
                    type="radio"
                    name="flexRadioDefault"
                    id={item.id as string}
                    label={item.name}
                    checked={item.id === planId}
                    onChange={e => setPlanId(item.id as number)}
                  />
                )
            )}
          </Modal>
          <UserDetail
            visible={visible}
            userId={userId}
            onClose={() => setIsVisible(false)}
          />
          <UserTable rowData={adminUsers} columnDefs={columnDefs} />
        </>
      )}
    </>
  );
};

export default Users;
