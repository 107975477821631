import React, { useEffect } from "react";
import { cilPencil } from "@coreui/icons";
import { ChildAccordionProps } from "../../../models/channel.model";
import { CButton, CListGroup, CListGroupItem } from "@coreui/react";
import CIcon from "@coreui/icons-react";

const AccordionChild: React.FC<ChildAccordionProps> = ({
  accordionList,
  onEdit,
}) => {
  if (accordionList && accordionList.length > 0) {
    return (
      <CListGroup>
        {accordionList.map(child => (
          <CListGroupItem key={child.id}>
            <span>{child.name}</span>
            <CButton color="link" onClick={() => onEdit?.(child.id)}>
              <CIcon icon={cilPencil} />
            </CButton>
          </CListGroupItem>
        ))}
      </CListGroup>
    );
  }
  return <></>;
};

export default AccordionChild;
