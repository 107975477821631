import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  RelativeVideoObject,
  RelativeVideoProps,
} from "../../models/relativevideo.model";
import _ from "lodash";
import { CButton, CToast, CToastBody } from "@coreui/react";

const AddRelativeVideo: React.FC<RelativeVideoProps> = ({
  relative_videos,
  selectedVideoId,
  onVideoAdd,
  activeRelativeVideo,
}) => {
  const [relativeVideo, setRelativeVideo] = useState<RelativeVideoObject[]>([]);
  const [selected, setSelected] = useState<any>();
  const [permissions, setPermissions] = useState<number[]>([3]);
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    if (selectedVideoId) {
      const filteredArray = _.filter(
        relative_videos,
        item => item?.media_id !== selectedVideoId
      );
      setRelativeVideo(filteredArray);
    } else {
      setRelativeVideo(relative_videos);
    }
    setSelected(0);
    setShowToast(false);
  }, [selectedVideoId, relative_videos]);

  function compareArraysById(array1: any[], array2: any[]) {
    const idsInArray2 = new Set(array2.map(item => item.media_id));
    const result = _.filter(array1, item => !idsInArray2.has(item.media_id));

    return result;
  }

  const handleAdd = (): void => {
    if (selected && selected.value) {
      const selectedVideo = _.find(
        relativeVideo,
        video => video?.media_id == selected.value
      );

      if (onVideoAdd) {
        onVideoAdd({
          ...selectedVideo,
          subscription_id: permissions,
        });
      }
      setSelected(null);
    } else {
      setShowToast(true);
    }
  };

  const handleSelectChange = (selectedOption: any): void => {
    setSelected(selectedOption);
    setShowToast(false);
  };

  const permissionChange = (evt: any): void => {
    const permission = evt?.target?.value;
    setPermissions(prevState => {
      return [...prevState, Number(permission)];
    });
  };

  const selectOptions = compareArraysById(
    relativeVideo,
    activeRelativeVideo
  ).map(video => ({
    label: video?.title,
    value: video?.media_id,
  }));

  return (
    <>
      <Select
        options={selectOptions}
        value={selected}
        onChange={handleSelectChange}
      />

      <div className="d-flex justify-content-end">
        <CButton className="btn btn-primary mt-2" onClick={handleAdd}>
          Add Related Video
        </CButton>
      </div>

      {showToast && (
        <CToast visible={showToast} autohide={false}>
          <CToastBody>Please select a related video</CToastBody>
        </CToast>
      )}
    </>
  );
};

export default AddRelativeVideo;
