import { StaticMenuApiData } from "../models/staticmenu.models";
import API_URL from "./apiConfig/api.config";
import apiCall from "./apiConfig/apiCall.config";

export async function deleteBodyArea(id: any) {
  return await apiCall.remove(API_URL.BODY_AREA.REMOVE_BODY_AREA + "/" + id);
}

export async function getAllBodyArea() {
  return await apiCall.get(API_URL.BODY_AREA.GET_ALL_BODY_AREA);
}

export async function getBodyPoint(path?: string) {
  return await apiCall.get(API_URL.BODY_AREA.GET_BODY_POINT + path);
}

export async function saveBodyArea(data: any) {
  return await apiCall.post(API_URL.BODY_AREA.SAVE_BODY_AREA, data);
}
