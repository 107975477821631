import { UpdateSubscribeProps } from "../models/user.model";
import API_URL from "./apiConfig/api.config";
import apiCall from "./apiConfig/apiCall.config";

export async function adminLogin(data: any) {
  return await apiCall.post(API_URL.USER.ADMIN_LOGIN, data);
}

export async function adminLogout(data: any) {
  return await apiCall.post(API_URL.USER.ADMIN_LOGOUT, data);
}
export async function getAdminUsers() {
  return await apiCall.get(API_URL.USER.ADMIN_GET_ALL_USERS);
}

export async function getAdminSubscribe() {
  return await apiCall.get(API_URL.USER.ADMIN_GET_SUBSCRIBE_LIST);
}

export async function getAdminUserDetail(data: any) {
  return await apiCall.post(API_URL.USER.ADMIN_GET_USER_DETAILS, data);
}

export async function changePassword(data: any) {
  return await apiCall.put(API_URL.USER.CHANGE_PASSWORD, data);
}

export async function changeSubscribe(data: UpdateSubscribeProps) {
  return await apiCall.put(API_URL.USER.CHANGE_SUBSCRIBE, data);
}
