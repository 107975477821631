import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { MediaCardProps, MediaObject } from "../../models/channel.model";
import {
  CCard,
  CCardBody,
  CCardImage,
  CCardTitle,
  CFormCheck,
  CImage,
} from "@coreui/react";
import _, { uniqueId } from "lodash";
import {
  COMMON_CONSTANTS,
  MEDIA_TYPES,
  POINT_CONSTANTS,
} from "../../constants/commonConstants";
import CIcon from "@coreui/icons-react";
import { cilMediaPlay } from "@coreui/icons";
import { CONSTANTS } from "../../constants";
import ConfirmModal from "../shared/ConfirmModal/ConfirmModal";

const MediaCard: React.FC<MediaCardProps> = ({
  mediaData,
  mediaArray = [],
  cardTitle = "",
  onClickImage,
  playButtonOverlay = false,
  updateData,
  type,
}) => {
  const options: Array<any> = [];
  const [confirmModal, setConfirmModal] = useState(false);
  const [media, setMedia] = useState<MediaObject>();
  const openModal = () => {
    setConfirmModal(true);
  };
  mediaData?.forEach(media => {
    if (media?.media_id && media?.title)
      options.push({
        value: media?.media_id || "",
        label: media?.title || "",
      });
  });
  const [activeMedia, setActiveMedia] = useState<MediaObject>();

  useEffect(() => {
    setActiveMedia(_.get(mediaArray, "[0]"));
  }, [mediaArray]);

  const handleOnChange = (value: any) => {
    const currentMedia = mediaData?.find(
      media => media?.media_id == value.value
    );
    if (currentMedia) {
      setActiveMedia(currentMedia);
      updateData && updateData(currentMedia);
    }
  };

  const handleSelectChange = (selectedOption: any) => {
    const currentMedia = mediaData?.find(
      (media: any) => media?.media_id === selectedOption.value
    );
    if (currentMedia) {
      if (currentMedia?.media_type === POINT_CONSTANTS.POINT_IMAGE) {
        setMedia(currentMedia);
        setConfirmModal(true);
      } else {
        setActiveMedia(currentMedia);
        updateData && updateData(currentMedia);
      }
    }
  };

  const animatedComponents = makeAnimated();

  const handlePlayClick = () => {
    if (activeMedia?.media_type === MEDIA_TYPES.VIDEO && onClickImage) {
      onClickImage?.(activeMedia);
    }
  };

  return (
    <>
      <ConfirmModal
        title="Are you certain you want to update the image? It will eliminate all existing points!"
        confirmButtonText="Confirm"
        cancelButtonText="Cancel"
        onConfirm={() => {
          setActiveMedia(media);
          media && updateData && updateData(media);
          setConfirmModal(false);
        }}
        onCancel={() => {
          setConfirmModal(false);
        }}
        isShow={confirmModal}
      ></ConfirmModal>
      <CCard className="h-100">
        <CCardBody>
          <CCardTitle>{cardTitle}</CCardTitle>
          {type === MEDIA_TYPES.VIDEO || type === MEDIA_TYPES.IMAGE ? (
            <div>
              {type === MEDIA_TYPES.VIDEO &&
                playButtonOverlay &&
                activeMedia?.file_name && (
                  <div className="play-button-overlay">
                    <CIcon
                      icon={cilMediaPlay}
                      className="play-button"
                      onClick={handlePlayClick}
                    />
                  </div>
                )}

              {activeMedia?.file_name && (
                <CCardImage
                  className="image-card"
                  onClick={() => {
                    onClickImage && onClickImage(activeMedia);
                  }}
                  orientation="top"
                  src={
                    activeMedia?.media_type === MEDIA_TYPES.VIDEO
                      ? activeMedia?.thumbnail_url ??
                        "/assets/images/media-placeholder.png"
                      : activeMedia?.file_name
                  }
                />
              )}
              {mediaData && mediaData?.length > 0 && (
                <div>
                  <label htmlFor="mediaSelect">Please Select a media</label>
                  <Select
                    id="mediaSelect"
                    className="mt-3"
                    onChange={handleSelectChange}
                    options={options}
                    value={{
                      value: activeMedia?.media_id || "",
                      label: activeMedia?.title,
                    }}
                    components={animatedComponents}
                  />
                </div>
              )}
            </div>
          ) : null}

          {type === MEDIA_TYPES.AUDIO && (
            <div>
              {activeMedia?.file_name && (
                <audio key={activeMedia?.file_name ?? "" + uniqueId} controls>
                  <source
                    src={activeMedia?.file_name || ""}
                    type="audio/mpeg"
                  />
                </audio>
              )}
              {mediaData && mediaData?.length > 0 && (
                <div className="dropdown-select">
                  <label htmlFor="mediaSelect1">Please Select an audio</label>
                  <Select
                    id="mediaSelect1"
                    className="mt-3"
                    onChange={handleOnChange}
                    value={{
                      value: activeMedia?.media_id || "",
                      label: activeMedia?.title || "Please Select",
                    }}
                    options={options}
                  />
                </div>
              )}
            </div>
          )}

          {type === MEDIA_TYPES.ASSOCIATED_VIDEO && (
            <div>
              {" "}
              {activeMedia?.file_name && (
                <CCardImage
                  className="image-card"
                  onClick={() => {
                    onClickImage && onClickImage(activeMedia);
                  }}
                  orientation="top"
                  src={
                    activeMedia?.media_type === MEDIA_TYPES.ASSOCIATED_VIDEO
                      ? activeMedia?.thumbnail_url ??
                        "/assets/images/media-placeholder.png"
                      : activeMedia?.file_name
                  }
                />
              )}
            </div>
          )}

          {type === MEDIA_TYPES.CHINESE_IMAGE && (
            <div>
              {" "}
              {activeMedia?.file_name && (
                <CCardImage
                  className="image-card"
                  onClick={() => {
                    onClickImage && onClickImage(activeMedia);
                  }}
                  orientation="top"
                  src={
                    activeMedia?.media_type === MEDIA_TYPES.VIDEO
                      ? activeMedia?.thumbnail_url ??
                        "/assets/images/media-placeholder.png"
                      : activeMedia?.file_name
                  }
                />
              )}
              {mediaData &&
                mediaData?.length > COMMON_CONSTANTS.MEDIA_LENGTH && (
                  <div>
                    <label htmlFor="mediaSelect">Please Select a media</label>
                    <Select
                      id="mediaSelect"
                      className="mt-3"
                      onChange={handleSelectChange}
                      options={options}
                      value={{
                        value: activeMedia?.media_id || "",
                        label: activeMedia?.title,
                      }}
                      components={animatedComponents}
                    />
                  </div>
                )}
            </div>
          )}
        </CCardBody>
      </CCard>
    </>
  );
};

export default MediaCard;
