import { ToastOptions, toast } from "react-toastify";
import { CONSTANTS } from "../constants";

const flattenObjectToString = function (ob: any) {
  const toReturn: any = {};
  let errorString: any = "";
  for (const i in ob) {
    if (!Object.prototype.hasOwnProperty.call(ob, i)) continue;
    if (typeof ob[i] == "object") {
      toReturn[i] = flattenObjectToString(ob[i]);
    } else {
      toReturn[i] = ob[i];
    }
  }
  for (const key in toReturn) {
    errorString =
      errorString === ""
        ? errorString + toReturn[key]
        : errorString + ", " + toReturn[key];
  }
  return errorString;
};

const toastService = (
  message: any,
  type: string = CONSTANTS.TOAST_TYPE.SUCCESS
) => {
  const config: ToastOptions = {
    autoClose: 5000,
    closeOnClick: true,
    draggable: true,
    hideProgressBar: false,
    pauseOnHover: true,
    position: "bottom-center",
    progress: undefined,
  };

  switch (type) {
    case CONSTANTS.TOAST_TYPE.ERROR:
      toast.error(message, { ...config });
      break;
    case CONSTANTS.TOAST_TYPE.INFO:
      toast.info(message, { ...config });
      break;
    case CONSTANTS.TOAST_TYPE.SUCCESS:
      toast.success(message, { ...config });
      break;
    case CONSTANTS.TOAST_TYPE.WARNING:
      toast.warning(message, { ...config });
      break;
  }
};

const commonUtils = {
  flattenObjectToString,
  toastService,
};

export default commonUtils;
