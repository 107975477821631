export const BODY_AREA_CONSTANTS = {
  BODY_AREA_SLUG: "/admin/body-area/",
  BODY_AREA_MAP_KEY: "body_area_map",
  BODY_AREA_MAP: "Body Area Map",
  BODY_AREA_TYPE: "body_area",
  BODY_IMAGE_FLAG: 0,
  INTERACTIVE_IMAGE_FLAG: 1,
  INTERACTIVE_MAP: "Interactive Map",
  INTERACTIVE_MAP_KEY: "interactive_map",
};

export const POINT_CONSTANTS = {
  ADD_POINT: "Add a point",
  BODY_AREA_MAP_IMAGE: "body_area_map_image",
  DEFAULT_CALC_VALUE: 1,
  IMAGE_INDEX_ONE: 1,
  IMAGE_INDEX_TWO: 2,
  IMAGE_INDEX_ZERO: 0,
  LARGE_SCREEN: 580,
  POINT_AREA_MAP_KEY: "point_area_map",
  POINT_AREA_MAP: "Image",
  POINT_IMAGE: "point_image",
  POINT_IMAGE_LARGE_SCREEN: "14px",
  POINT_IMAGE_SMALL_SCREEN: "7px",
  SMALL_SCREEN: 273,
};
