export const PASSWORD_TYPE_CONSTANTS = {
  CONFIRM: "confirm",
  NEW: "new",
  OLD: "old",
  PASSWORD_REGEX: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=]).{8,}$/,
};

export const USER_CONSTANTS = {
  PAGE_COUNT_LIST: [10, 20, 50, 100, 500],
  PRO_ID: 3,
  TRAIL_ID: 4,
};
