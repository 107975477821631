import React from "react";

import AdminLayout from "./layouts/AdminLayout";
import "@coreui/coreui/dist/css/coreui.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";
import Login from "./pages/Login/Login";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import MainRoute from "./routes/MainRoute";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <ToastContainer position="bottom-center" />
      <MainRoute />
    </BrowserRouter>
  );
};

export default App;
