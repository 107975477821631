import React, { useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../context/userContext";
import Login from "../pages/Login/Login";
import AdminLayout from "./AdminLayout";

function LayoutProtected({ children }: any) {
  const { user }: any = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/", { replace: true });
    }
  }, [user, navigate]);

  if (!user) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <AdminLayout>
        <Outlet />
      </AdminLayout>
    </>
  );
}

export default LayoutProtected;
