import React, { FunctionComponent } from "react";
import { CSpinner } from "@coreui/react";
import { pageLoaderProps } from "../../models/page.loader.model";
const PageLoader: FunctionComponent<pageLoaderProps> = (
  props: pageLoaderProps
) => {
  const showContent: boolean =
    (typeof props?.overlayOnly !== "undefined" &&
      props?.overlayOnly === false) ||
    typeof props?.overlayOnly === "undefined"
      ? true
      : false;
  return (
    <>
      <div className={`mp-page-loader ${!props.isLoading && "d-none"}`}>
        <div className="text-center">
          {showContent && (
            <>
              <CSpinner color="danger"></CSpinner>
              <div className="mp-text mp-bg-coffee mp-text-white py-1 px-3 rounded">
                Loading...
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PageLoader;
