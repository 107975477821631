import React, { useEffect, useState } from "react";
import {
  CAccordion,
  CAccordionHeader,
  CAccordionItem,
  CAlert,
  CButton,
} from "@coreui/react";

import { useNavigate } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import { cilPencil } from "@coreui/icons";
import { MenuItem } from "../../models/staticmenu.models";
import PageLoader from "../../components/PageLoader/PageLoader";
import { getAllBodyArea } from "../../services/bodyArea.service";
import { BODY_AREA_CONSTANTS } from "../../constants/bodyAreaConstants";

const BodyArea: React.FC = () => {
  const [bodyArea, setBodyAraList] = useState<MenuItem[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    handleGetChannelMenu();
  }, []);

  const filterMenuItems = (item: MenuItem) => {
    if (item.name.toLowerCase().includes(searchTerm.trim().toLowerCase())) {
      return true;
    }
    if (item.children) {
      return item.children.some(filterMenuItems);
    }
    return false;
  };

  const handleGetChannelMenu = async () => {
    try {
      const bodyAreaData = await getAllBodyArea();
      setBodyAraList(bodyAreaData?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handelOptions = (data: MenuItem) => {
    navigate(BODY_AREA_CONSTANTS.BODY_AREA_SLUG + data.slug);
  };

  const filteredMenu = bodyArea.filter(filterMenuItems);
  const noResultsFound = !loading && filteredMenu.length === 0;
  const navigate = useNavigate();
  return (
    <>
      <PageLoader isLoading={loading} />
      <div className="d-flex justify-content-end mb-2">
        <input
          className="d-flex mb-2 "
          type="text"
          placeholder="Search menu..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="body-area">
        {noResultsFound && (
          <CAlert color="info" className="text-center mb-3">
            No results found.
          </CAlert>
        )}
        <CAccordion>
          {filteredMenu.map(data => (
            <CAccordionItem key={data.id}>
              <CAccordionHeader>
                {data.name}
                <CButton color="link" onClick={() => handelOptions(data)}>
                  <CIcon icon={cilPencil} />
                </CButton>
              </CAccordionHeader>
            </CAccordionItem>
          ))}
        </CAccordion>
      </div>
      <PageLoader />
    </>
  );
};

export default BodyArea;
