import React, { ChangeEvent, useState, useEffect } from "react";
import { MediaModalProps } from "../../models/media.models";
import { CButton, CCol, CFormInput, CFormLabel, CRow } from "@coreui/react";
import {
  COMMON_CONSTANTS,
  MEDIA_TYPES,
  MEDIA_VALUE,
} from "../../constants/commonConstants";
import { uniqueId } from "lodash";
import { checkFileType } from "../../services/hooks/errorHooks";
import { MESSAGE_CONSTANT } from "../../constants/messageConstants";
import { FIELD_CONSTANTS } from "../../constants/fieldConstants";

const MediaModal: React.FC<MediaModalProps> = ({
  onClose,
  type = COMMON_CONSTANTS.EMPTY,
  isFileUploadModalOpen,
  editData,
  saveMedia,
  activeMediaTab,
}) => {
  const [audioPreview, setAudioPreview] = useState<string | undefined>(
    editData.file_name ?? COMMON_CONSTANTS.EMPTY
  );
  const [error, setError] = useState<string>(COMMON_CONSTANTS.EMPTY);
  const [file, setFile] = useState<File | null>(COMMON_CONSTANTS.NULL);
  const [imagePreview, setImagePreview] = useState<string | undefined>(
    editData.file_name ?? COMMON_CONSTANTS.EMPTY
  );
  const [mediaType, setMediaType] = useState<string>(
    editData.media_type ?? COMMON_CONSTANTS.EMPTY
  );
  const [titleVariable, setTitleVariable] = useState<string>(
    editData.title ?? COMMON_CONSTANTS.EMPTY
  );
  const [titleError, setTitleError] = useState(COMMON_CONSTANTS.EMPTY);
  const [typeVariable, setTypeVariable] = useState<string>(
    COMMON_CONSTANTS.EMPTY
  );
  const [videoPreview, setVideoPreview] = useState<string | undefined>(
    editData.file_name ?? COMMON_CONSTANTS.EMPTY
  );

  const formData: any = new FormData();

  const acceptValue =
    activeMediaTab === MEDIA_TYPES.IMAGE
      ? MEDIA_VALUE.IMAGE
      : activeMediaTab === MEDIA_TYPES.VIDEO
      ? MEDIA_VALUE.VIDEO
      : activeMediaTab === MEDIA_TYPES.AUDIO
      ? MEDIA_VALUE.AUDIO
      : COMMON_CONSTANTS.EMPTY;

  const fileUpload = () => {
    formData.append("mediafile", file);
    formData.append("media_type", mediaType);
    formData.append("title", titleVariable);

    if (editData?.type === undefined) {
      formData.append("type", typeVariable);
    } else {
      formData.append("type", editData?.type);
    }
    if (editData?.media_id !== undefined) {
      formData.append("media_id", editData?.media_id);
    }
    if (file === null) {
      formData.delete("mediafile");
    }
    if (titleVariable === COMMON_CONSTANTS.EMPTY) {
      setTitleError(MESSAGE_CONSTANT.TITLE_REQUIRED);
    } else if (file === null && imagePreview === "") {
      setError(MESSAGE_CONSTANT.FILE_REQUIRED);
    } else {
      setTitleError(COMMON_CONSTANTS.EMPTY);
      setError(COMMON_CONSTANTS.EMPTY);
      saveMedia?.(formData, editData);
    }
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (
      e.target.files &&
      e.target.files?.length > COMMON_CONSTANTS.INITIAL_VALUE
    ) {
      const newFile: File = e.target.files[COMMON_CONSTANTS.INITIAL_VALUE];
      const res = checkFileType(newFile, activeMediaTab as MEDIA_TYPES);
      if (res.status) {
        setError(COMMON_CONSTANTS.EMPTY);
        setTitleError(COMMON_CONSTANTS.EMPTY);
        setFile(e.target.files[COMMON_CONSTANTS.INITIAL_VALUE]);
        switch (activeMediaTab) {
          case MEDIA_TYPES.CHINESE_IMAGE:
            setMediaType(MEDIA_TYPES.CHINESE_IMAGE);
            setImagePreview(URL.createObjectURL(newFile));
            break;
          case MEDIA_TYPES.IMAGE:
            if (newFile.type.includes(MEDIA_TYPES.IMAGE)) {
              setMediaType(MEDIA_TYPES.IMAGE);
              setImagePreview(URL.createObjectURL(newFile));
            }
            break;
          case MEDIA_TYPES.VIDEO:
            if (newFile.type.includes(MEDIA_TYPES.VIDEO)) {
              setMediaType(MEDIA_TYPES.VIDEO);
              setVideoPreview(URL.createObjectURL(newFile));
            }
            break;
          case MEDIA_TYPES.AUDIO:
            if (newFile.type.includes(MEDIA_TYPES.AUDIO)) {
              setMediaType(MEDIA_TYPES.AUDIO);
              setAudioPreview(URL.createObjectURL(newFile));
            }
            break;
          default:
            break;
        }

        setTitleVariable(newFile.name);
        setTypeVariable(type);
      } else {
        setError(res.message);
      }
    }
  };

  return (
    <>
      <CRow className="mb-3">
        <CFormLabel htmlFor="staticEmail" className="col-sm-2 col-form-label">
          {FIELD_CONSTANTS.TITLE} {<span className="text-danger">*</span>}
        </CFormLabel>
        <CCol sm={10}>
          <CFormInput
            className="mb-2"
            type="text"
            value={titleVariable}
            onChange={e => {
              setTitleError(COMMON_CONSTANTS.EMPTY);
              setTitleVariable(e.target.value);
            }}
            id="title"
            placeholder="Enter Title"
            aria-describedby="exampleFormControlInputHelpInline"
          />
        </CCol>
      </CRow>{" "}
      <div className="error-message">{titleError}</div>
      <div>
        <div className="text-center">
          {(mediaType === MEDIA_TYPES.IMAGE ||
            mediaType === MEDIA_TYPES.CHINESE_IMAGE) &&
            imagePreview && (
              <img
                className="image-upload"
                src={imagePreview}
                alt="Image Preview"
              />
            )}

          {mediaType === MEDIA_TYPES.VIDEO && videoPreview && (
            <video
              key={videoPreview ?? COMMON_CONSTANTS.EMPTY + uniqueId}
              controls
              width="320"
              height="240"
            >
              <source src={videoPreview} type="video/mp4" />
            </video>
          )}

          {mediaType === MEDIA_TYPES.AUDIO && audioPreview && (
            <audio
              key={audioPreview ?? COMMON_CONSTANTS.EMPTY + uniqueId}
              controls
            >
              <source src={audioPreview} type="audio/mpeg" />
            </audio>
          )}
        </div>
        <div className="error-message">{error}</div>
        <div className="custom-modal-body position-relative text-end">
          <CButton className="mb-2" color="primary">
            {FIELD_CONSTANTS.UPLOAD_MEDIA}
          </CButton>

          <input
            className="input-file"
            type="file"
            accept={acceptValue}
            onChange={handleFileChange}
          />
        </div>
      </div>
      <div className="modal-footer  d-flex justify-content-end">
        <CButton
          color="secondary"
          className="cancel-button mx-2"
          type="button"
          onClick={onClose}
        >
          {FIELD_CONSTANTS.CANCEL}
        </CButton>
        <CButton
          color="danger"
          className="upload-button "
          type="button"
          onClick={fileUpload}
        >
          {FIELD_CONSTANTS.SAVE}
        </CButton>
      </div>
    </>
  );
};

export default MediaModal;
