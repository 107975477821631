import apisauce from "apisauce";
import { BASE_URL } from "../../app.config";
import commonService from "../common.service";

const Create: any = (baseURL: any = BASE_URL) => {
  const api = apisauce.create({
    baseURL,
    timeout: 100000,
  });

  const setHeader = async () => {
    const apiHeader = await commonService.getApiHeader();
    api.setHeaders(apiHeader);
  };

  const get = async (url: string, customHeader?: any) => {
    if (customHeader) api.setHeaders(customHeader);
    else await setHeader();
    return api.get(url);
  };

  const patch = async (url: string, body?: any, customHeader?: any) => {
    if (customHeader) api.setHeaders(customHeader);
    else await setHeader();
    return api.patch(url, body);
  };

  const post = async (url: string, body?: any) => {
    await setHeader();
    return api.post(url, body);
  };

  const put = async (url: string, body?: any, customHeader?: any) => {
    if (customHeader) api.setHeaders(customHeader);
    else await setHeader();
    return api.put(url, body);
  };

  const remove = async (url: string, body?: any) => {
    await setHeader();
    return api.delete(url, body);
  };

  return {
    get,
    patch,
    post,
    remove,
    put,
    api,
  };
};

export default {
  Create,
};
