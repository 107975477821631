import React, { useEffect, useRef, useState } from "react";
import {
  CButton,
  CNavLink,
  CNav,
  CTabPane,
  CNavItem,
  CTabContent,
  CFormInput,
  CCardImage,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CRow,
  CCol,
  CToast,
  CToastHeader,
  CToaster,
} from "@coreui/react";
import { useParams } from "react-router";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from "draft-js";
import API_SERVICES from "../../services";
import {
  LanguageData,
  StaticPageState,
  StaticPageVideoData,
  VideoData,
} from "../../models/staticmenu.models";
import commonService from "../../services/common.service";
import handleResponse from "../../services/apiConfig/api.response.service";
import {
  getChannelMenuPage,
  getMediaTypeChannel,
} from "../../services/channel.service";
import PageLoader from "../../components/PageLoader/PageLoader";
import { useNavigate } from "react-router-dom";
import {
  COMMON_CONSTANTS,
  MEDIA_TYPES_CHANNELS,
} from "../../constants/commonConstants";
import VideoCard from "../../components/VideoCard/VideoCard";
import _ from "lodash";
import { CONSTANTS } from "../../constants";
import { CToastBody } from "@coreui/react";
import { Language, TitleError } from "../../models/channel.model";
import {
  ToastContent,
  getPageContent,
  languages,
} from "../../utils/editor.utils";

const ChannelTitle: React.FC = () => {
  const [activeMediaId, setActiveMediaId] = useState<number | null>();
  const [allMediaData, setAllMediaData] = useState<any | null>();
  const [errors, setErrors] = useState<TitleError>({});
  const [language, setLanguage] = useState("en");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenToaster, setIsOpenToaster] = useState(false);
  const [isTitleValid, setIsTitleValid] = useState(true);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [staticPage, setStaticPage] = useState<StaticPageState>({
    en: {
      title: "",
      text: "",
      video: [],
    },
    de: {
      title: "",
      text: "",
      video: [],
    },
    es: {
      title: "",
      text: "",
      video: [],
    },
    ru: {
      title: "",
      text: "",
      video: [],
    },
  });
  const [titleMessage, setTitleMessage] = useState("");
  const toaster = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const params = useParams<{ uuid: string }>();

  useEffect(() => {
    saveChannelMenu();
  }, []);

  useEffect(() => {
    fetchAllMediaType();
  }, []);

  useEffect(() => {
    if (isOpenToaster) {
      ToastContent(isOpenToaster, errors);
      setIsOpenToaster(false);
    }
  }, [errors]);

  const fetchAllMediaType = async () => {
    const [video] = await Promise.all([
      getMediaTypeChannel("channel", MEDIA_TYPES_CHANNELS.VIDEO),
    ]);
    setAllMediaData({
      video: video.data,
    });
  };

  const handleMedia = (data: StaticPageVideoData) => {
    if (data.media_type === MEDIA_TYPES_CHANNELS.VIDEO) {
      setStaticPage((prevStaticPage: StaticPageState) => {
        const list: any = _.cloneDeep(prevStaticPage);
        if (
          list[selectedLanguage] &&
          list[selectedLanguage].video &&
          list[selectedLanguage].video[0]
        ) {
          data.point_media_id = list[selectedLanguage].video[0].point_media_id;
          list[selectedLanguage].video = [data];
        }
        return list;
      });
    }
  };

  const handleCancel = () => {
    setStaticPage(staticPage);
    setIsTitleValid(true);
    setTitleMessage("");
    navigate(COMMON_CONSTANTS.PREVIOUS_PAGE);
  };

  const handleOptions = () => {
    setIsLoading(true);
    const newObj: any = {};
    const test = getPageContent(staticPage);

    if (Object.keys(test).length > 0) {
      setErrors(test);
      setIsOpenToaster(true);
      setIsLoading(false);
    } else {
      Object.keys(staticPage).forEach(languageKey => {
        newObj[languageKey] = {
          title: staticPage[languageKey]?.title,
          media_id:
            (staticPage[languageKey]?.video as VideoData[])?.length > 0
              ? (staticPage[languageKey]?.video as VideoData[])[0]?.media_id
              : null,
        };
      });

      const payload = {
        channel_id: params.uuid,
        locales: newObj,
      };
      API_SERVICES.CHANNEL.SAVE_CHANNEL_MENU(payload)
        .then((data: any) => {
          const responseData: any = handleResponse(data);
          if (responseData.ok) {
            commonService.toastService(
              responseData.data,
              CONSTANTS.STATUS.SUCCESS
            );
          } else {
            commonService.toastService(
              "Failed to update static menu",
              CONSTANTS.STATUS.FAILED
            );
          }
          setIsLoading(false);
        })
        .catch(error => {
          console.error("Error saving channel menu:", error);
          commonService.toastService(
            "An error occurred while saving.",
            "error"
          );
        });
    }
  };

  const handleTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    language: keyof StaticPageState
  ) => {
    const newTitle = event.target.value;

    setStaticPage((prevState: any) => ({
      ...prevState,
      [language]: {
        ...prevState[language],
        title: newTitle,
      },
    }));
    selectedLanguage === "en" && setIsTitleValid(newTitle.trim() !== "");
    if (newTitle.trim() === "") {
      selectedLanguage === "en" &&
        setTitleMessage("English Title is required ");
    } else {
      setTitleMessage("");
    }
  };

  const openVideoModal = () => {
    setIsVideoModalOpen(true);
  };

  const saveChannelMenu = async () => {
    const data = await getChannelMenuPage(params?.uuid as string);
    setStaticPage(data.data);
    setActiveMediaId(data?.data[selectedLanguage].video[0]?.media_id);
  };

  return (
    <>
      <CNav variant="tabs" role="tablist">
        {languages.map((lang: any) => (
          <CNavItem role="presentation" key={lang.id}>
            <CNavLink
              active={selectedLanguage === lang.code}
              component="button"
              role="tab"
              aria-controls={`tab-${lang.code}`}
              aria-selected={selectedLanguage === lang.code}
              onClick={() => setSelectedLanguage(lang.code)}
            >
              {lang.name}
            </CNavLink>
          </CNavItem>
        ))}
      </CNav>
      <CTabContent>
        {languages.map((lang: Language) => (
          <CTabPane
            key={lang.code}
            role="tabpanel"
            aria-labelledby={`tab-${lang.code}`}
            visible={selectedLanguage === lang.code}
          >
            <CFormInput
              className="form-title"
              type="text"
              value={staticPage[lang.code]?.title || ""}
              onChange={e =>
                handleTitleChange(e, lang.code as keyof StaticPageState)
              }
            />
            {titleMessage && (
              <div className="error-message">{titleMessage}</div>
            )}
          </CTabPane>
        ))}
      </CTabContent>{" "}
      <CRow className="mt-3">
        {(staticPage[selectedLanguage] as LanguageData)?.type !==
          "extra_ordinary" && (
          <CCol sm={6}>
            <VideoCard
              mediaData={allMediaData?.video}
              cardTitle="Video"
              mediaArray={
                staticPage[selectedLanguage]?.video as StaticPageVideoData[]
              }
              onClickImage={openVideoModal}
              onMediaChange={media_id => {
                setStaticPage((prevStaticPage: StaticPageState) => {
                  const list: StaticPageState = _.cloneDeep(prevStaticPage);
                  if (
                    list[selectedLanguage] &&
                    list[selectedLanguage]?.video &&
                    (list[selectedLanguage]?.video as VideoData[])[0]
                  ) {
                    (list[selectedLanguage]?.video as VideoData[])[0].media_id =
                      media_id as number;
                  } else {
                    const id = media_id as number;
                    list[selectedLanguage] = {
                      ...list[selectedLanguage],
                      video: [{ media_id: id }],
                    };
                  }
                  return list;
                });
                setActiveMediaId(media_id);
              }}
              updateData={data => {
                handleMedia(data as StaticPageVideoData);
              }}
            />
          </CCol>
        )}
        <PageLoader isLoading={isLoading} overlayOnly={false} />
        <CModal
          visible={isVideoModalOpen}
          onClose={() => {
            setIsVideoModalOpen(false);
          }}
        >
          <CModalHeader>
            <CModalTitle>Video</CModalTitle>
          </CModalHeader>
          <CModalBody>
            <video className="w-100" controls>
              <source
                src={
                  staticPage[selectedLanguage]?.video &&
                  (staticPage[selectedLanguage]?.video as VideoData[])[0]
                    ?.file_name
                }
                type="video/mp4"
              />
            </video>
          </CModalBody>
        </CModal>{" "}
      </CRow>
      <div className="mp-button-container">
        <CButton className="me-2 " color="secondary" onClick={handleCancel}>
          Cancel
        </CButton>
        <CButton
          color="danger"
          onClick={handleOptions}
          disabled={!isTitleValid}
        >
          Save
        </CButton>
      </div>
    </>
  );
};

export default ChannelTitle;
