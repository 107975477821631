import React, { useEffect, useState } from "react";
import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CAlert,
  CButton,
  CListGroup,
  CListGroupItem,
  CSpinner,
} from "@coreui/react";

import { useNavigate } from "react-router-dom";
import CIcon from "@coreui/icons-react";
import { cilPencil } from "@coreui/icons";
import { find } from "lodash";
import { getChannelMenu } from "../../services/channel.service";
import { MenuItem } from "../../models/staticmenu.models";
import PageLoader from "../../components/PageLoader/PageLoader";
import { getChannelPoints } from "../../services/point.service";
import { ChannelPoints, PointObject } from "../../models/channel.model";
import AccordionChild from "../../components/shared/Accordion/AccordianChild";

const StaticMenuList: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [staticMenu, setStaticMenuList] = useState<MenuItem[]>([]);
  const [points, setPoints] = useState<ChannelPoints[]>([]);

  useEffect(() => {
    handleGetChannelMenu();
  }, []);

  const filterMenuItems = (item: MenuItem) => {
    if (item.name.toLowerCase().includes(searchTerm.trim().toLowerCase())) {
      return true;
    }
    if (item.children) {
      return item.children.some(filterMenuItems);
    }
    return false;
  };

  const handleClick = (itemId: number) => {
    navigate("/admin/edit-point/" + itemId);
  };
  const handleEdit = (itemId: number) => {
    navigate("/admin/edit-channel/" + itemId);
  };

  const handleGetChannelMenu = async () => {
    try {
      const [channelMenu, ChannelPoints] = await Promise.all([
        getChannelMenu(),
        getChannelPoints(),
      ]);
      setStaticMenuList(channelMenu?.data);
      setPoints(ChannelPoints?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching menu:", error);
      setLoading(false);
    }
  };
  const handelOptions = (itemId: number) => {
    navigate("/admin/edit-channel-title/" + itemId);
  };

  const renderChannelPoints = (channelId: number): PointObject[] => {
    return find(points, point => point.channel_id === channelId)?.points || [];
  };
  const filteredMenu = staticMenu.filter(filterMenuItems);
  const noResultsFound = !loading && filteredMenu.length === 0;
  const navigate = useNavigate();
  return (
    <>
      <PageLoader isLoading={loading} />
      <div className="d-flex justify-content-end mb-2">
        <input
          className="d-flex mb-2 "
          type="text"
          placeholder="Search menu..."
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
        />
      </div>
      <div>
        {noResultsFound && (
          <CAlert color="info" className="text-center mb-3">
            No results found.
          </CAlert>
        )}
        <CAccordion>
          {filteredMenu.map(data => (
            <CAccordionItem key={data.id}>
              <CAccordionHeader>
                {data.name}
                <CButton color="link" onClick={() => handelOptions(data.id)}>
                  <CIcon icon={cilPencil} />
                </CButton>
              </CAccordionHeader>
              <CAccordionBody>
                <>
                  <AccordionChild
                    accordionList={data.children}
                    onEdit={handleEdit}
                  />
                  <div style={{ height: "20px" }}></div>
                  <AccordionChild
                    accordionList={renderChannelPoints(data.id)}
                    onEdit={handleClick}
                  />
                </>
              </CAccordionBody>
            </CAccordionItem>
          ))}
        </CAccordion>
      </div>
      <PageLoader />
    </>
  );
};

export default StaticMenuList;
