import { Route, Routes } from "react-router-dom";
import React from "react";
import Login from "../pages/Login/Login";
import { AuthProvider } from "../context/userContext";
import LayoutProtected from "../layouts/LayoutProtected";
import AdminLayout from "../layouts/AdminLayout";
import Channels from "../pages/Channels/Channels";
import EditChannel from "../pages/EditChannel/EditChannel";
import ChannelTitle from "../pages/ChannelTitle/ChannelTitle";
import Dashboard from "../pages/Dashboard/Dashboard";
import NotFoundPage from "../pages/NotFoundPage/NotFoundPage";
import StaticMenuList from "../pages/StaticMenuList/StaticMenuList";
import StaticMenuItem from "../pages/StaticMenuItem/StaticMenuItem";
import Users from "../pages/Users/Users";
import UserDetail from "../pages/Users/UserDetail";
import StaticTitle from "../pages/StaticTitle/StaticTitle";
import Points from "../pages/Points/Points";
import MediaList from "../pages/MediaList/MediaList";
import BodyArea from "../pages/BodyArea/BodyArea";
import BodyPoint from "../pages/BodyPoint/BodyPoint";
import ChangePassword from "../pages/ChangePassword/ChangePassword";

const MainRoute = () => {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/admin" element={<LayoutProtected />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="users" element={<Users />} />
          <Route path="static-pages" element={<StaticMenuList />} />
          <Route path="edit-static-title/:uuid" element={<StaticTitle />} />
          <Route path="edit-static-page/:uuid" element={<StaticMenuItem />} />
          <Route path="edit-channel-title/:uuid" element={<ChannelTitle />} />
          <Route path="channels" element={<Channels />} />
          <Route path="body-area" element={<BodyArea />} />
          <Route path="body-area/:area" element={<BodyPoint />} />
          <Route path="edit-channel/:uuid" element={<EditChannel />} />
          <Route path="edit-point/:uuid" element={<Points />} />
          <Route path="media-list" element={<MediaList />} />
          <Route path="user/:uuid" element={<UserDetail />} />
          <Route path="change-password" element={<ChangePassword />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </AuthProvider>
  );
};

export default MainRoute;
