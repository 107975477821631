import {
  CButton,
  CFormCheck,
  CFormSelect,
  CTableHeaderCell,
  CTableRow,
} from "@coreui/react";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { RelativeMediaCardProps } from "../../models/relativevideo.model";
import {
  COMMON_CONSTANTS,
  RELATIVE_VIDEO,
} from "../../constants/commonConstants";
import { CTableDataCell } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilTrash } from "@coreui/icons";

const permissionArray = [
  { id: 0, value: 0, label: "All" },
  { id: 1, value: 1, label: "Lite" },
  { id: 2, value: 2, label: "Standard" },
  { id: 3, value: 3, label: "Pro" },
];

const createLabelObject = (selectedIds: number[]): Record<string, boolean> => {
  const labelObject: Record<string, boolean> = {};

  _.forEach(permissionArray, item => {
    labelObject[item.label] = _.includes(selectedIds, item.id);
  });
  if (selectedIds.length == COMMON_CONSTANTS.VIDEO_PERMISSION_ALL) {
    labelObject.All = true;
  }
  return labelObject;
};
const RelativeMediaCard: React.FC<RelativeMediaCardProps> = ({
  media,
  updatedPermission,
  onClickImage,
  onDelete,
}) => {
  const [permissions, setPermissions] = useState<number[]>([]);
  const [selected, setSelected] = useState<any>({
    All: false,
    Lite: false,
    Standard: false,
    Pro: false,
  });

  const [permissiondata, setPermissiondata] = useState([]);

  useEffect(() => {
    const allowedPermissions = createLabelObject(media.subscription_id);
    setSelected(allowedPermissions);
  }, [media]);

  const handleClickImage = () => {
    if (onClickImage) onClickImage(media);
  };

  const handleDelete = () => {
    if (onDelete) onDelete(media);
  };

  const handleSelectChange = (e: any, item: any): void => {
    const { checked } = e.target;
    updatedPermission(e?.target?.value, checked, media.media_id, item);
  };

  return (
    <>
      <CTableRow>
        <CTableHeaderCell scope="row" onClick={handleClickImage}>
          {" "}
          <CButton color="link">{media.title}</CButton>
        </CTableHeaderCell>
        <CTableDataCell>
          {permissionArray.map(item => (
            <CFormCheck
              className={
                item.id === COMMON_CONSTANTS.VIDEO_PERMISSION_ALL
                  ? "d-none"
                  : ""
              }
              key={item.id}
              label={item.label}
              value={item.value}
              checked={selected[item.label]}
              onChange={e => handleSelectChange(e, item.label)}
            />
          ))}
        </CTableDataCell>
        <CTableDataCell>
          <CButton color="link" onClick={() => handleDelete()}>
            <CIcon icon={cilTrash} size="xl" />
          </CButton>
        </CTableDataCell>
      </CTableRow>
    </>
  );
};

export default RelativeMediaCard;
