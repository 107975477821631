import React, { useEffect, useState } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilLockLocked, cilUser } from "@coreui/icons";
import API_SERVICES from "../../services";
import commonService from "../../services/common.service";
import { LoginProps } from "../../models/user.model";
import { useNavigate } from "react-router-dom";
import { CONSTANTS } from "../../constants";
import { useAuth } from "../../context/userContext";
import handleResponse from "../../services/apiConfig/api.response.service";
function validateEmail(email: string): boolean {
  const pattern: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return pattern.test(email);
}

const Login: React.FC = () => {
  const [formData, setFormData] = useState<LoginProps>({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState<boolean>(false);
  const [formValid, setFormValid] = useState(false);
  const { login }: any = useAuth();
  const { user }: any = useAuth();

  useEffect(() => {
    if (user) {
      navigate("/admin/dashboard", { replace: true });
    }
  }, [user, navigate]);

  const handleOnChange = (event: any) => {
    const { name, value } = event.target;
    setFormData(prevState => {
      return {
        ...prevState,
        [name]: value,
      };
    });

    if (name === "email" && !validateEmail(value)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const handleLogin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setFormValid(false);
    const form = event.currentTarget;
    if (!form?.checkValidity()) {
      setFormValid(true);
      return;
    }
    if (!validateEmail(formData?.email)) {
      setEmailError(true);
      return;
    }
    setEmailError(false);

    API_SERVICES.USER.ADMIN_LOGIN(formData).then((data: any) => {
      const responseData: any = handleResponse(data);
      if (responseData.ok) {
        const tokens: any = {};
        const accessToken = responseData?.data?.token;
        tokens[CONSTANTS.LOCAL_STORAGE.ACCESS_TOKEN] = accessToken;
        const userdata = responseData?.data?.userdata;
        commonService.setLocalStorage(tokens);
        login({ ...userdata, accessToken });
        commonService.toastService("login successful", "success");
      } else {
        commonService.toastService("Invalid Credentials", "error");
      }
    });
  };

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={7} lg={6} xl={5}>
            <div className="header">
              <img
                className="logo"
                src={"/assets/images/moa-logo-s-header.svg"}
                alt="Manual of Acupuncture logo"
              />
            </div>
            <CCardGroup>
              <CCard className="p-4 ">
                <CCardBody>
                  <CForm
                    noValidate
                    validated={formValid}
                    onSubmit={handleLogin}
                  >
                    <h1>Login</h1>
                    <p className="text-medium-emphasis">
                      Sign In to your account
                    </p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        placeholder="Username"
                        autoComplete="username"
                        name="email"
                        feedbackInvalid="Please provide a valid email."
                        required
                        onChange={handleOnChange}
                        value={formData?.email}
                        invalid={emailError}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Password"
                        name="password"
                        autoComplete="current-password"
                        feedbackInvalid="Please provide a valid password."
                        required
                        onChange={handleOnChange}
                        value={formData?.password}
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol className="d-flex justify-content-end">
                        <CButton color="danger" className="px-4" type="submit">
                          Login
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;
