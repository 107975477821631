import { COMMON_CONSTANTS, MEDIA_TYPES } from "../../constants/commonConstants";
import { MESSAGE_CONSTANT } from "../../constants/messageConstants";

export const checkFileType = (
  file: File,
  type: MEDIA_TYPES
): { status: boolean; message: string } => {
  const allowedTypes: { [key: string]: string[] } = {
    [MEDIA_TYPES.CHINESE_IMAGE]: ["jpeg", "jpg", "png", "gif"],
    [MEDIA_TYPES.IMAGE]: ["jpeg", "jpg", "png", "gif"],
    [MEDIA_TYPES.AUDIO]: ["mp3", "wav", "ogg"],
    [MEDIA_TYPES.VIDEO]: ["mp4"],
    [MEDIA_TYPES.ASSOCIATED_VIDEO]: ["mp4"],
  };

  const typeMessage: { [key: string]: string } = {
    [MEDIA_TYPES.AUDIO]: MESSAGE_CONSTANT.INVALID_AUDIO_FILE,
    [MEDIA_TYPES.ASSOCIATED_VIDEO]: MESSAGE_CONSTANT.INVALID_VIDEO_FILE,
    [MEDIA_TYPES.CHINESE_IMAGE]: MESSAGE_CONSTANT.INVALID_IMAGE_FILE,
    [MEDIA_TYPES.IMAGE]: MESSAGE_CONSTANT.INVALID_IMAGE_FILE,
    [MEDIA_TYPES.VIDEO]: MESSAGE_CONSTANT.INVALID_VIDEO_FILE,
    default: MESSAGE_CONSTANT.UNKNOWN_MEDIA_TYPE,
  };

  if (type in allowedTypes) {
    if (!validateFileType(file, type)) {
      return {
        status: false,
        message: typeMessage[type] || typeMessage["default"],
      };
    }
    if (file.size >= COMMON_CONSTANTS.FIVE_MB) {
      return {
        status: false,
        message: MESSAGE_CONSTANT.FILE_SIZE_LIMIT_EXCEEDED,
      };
    }
    return { status: true, message: COMMON_CONSTANTS.EMPTY };
  } else {
    return { status: false, message: typeMessage["default"] };
  }
};

const validateFileType = (file: File, mediaType: MEDIA_TYPES): boolean => {
  const allowedExtensions: { [key in MEDIA_TYPES]: string[] } = {
    [MEDIA_TYPES.ASSOCIATED_VIDEO]: ["mp4"],
    [MEDIA_TYPES.AUDIO]: ["mp3", "wav", "ogg"],
    [MEDIA_TYPES.CHINESE_IMAGE]: ["jpeg", "jpg", "png", "gif"],
    [MEDIA_TYPES.IMAGE]: ["jpeg", "jpg", "png", "gif"],
    [MEDIA_TYPES.VIDEO]: ["mp4"],
  };

  const fileExtension = file.name.split(".").pop()?.toLowerCase();
  if (!fileExtension) {
    return false;
  }

  return allowedExtensions[mediaType].includes(fileExtension);
};
