import React, { useEffect, useState } from "react";
import MediaGrid from "./MediaGrid";
import { CCardImage, CCol, CRow } from "@coreui/react";
import { MediaGridListProps } from "../../models/media.models";
import Modal from "../coreui/Modal";
import { MediaObject } from "../../models/channel.model";
import { MEDIA_TYPES } from "../../constants/commonConstants";
import ConfirmModal from "../shared/ConfirmModal/ConfirmModal";
import commonService from "../../services/common.service";
import API_SERVICES from "../../services";
import handleResponse from "../../services/apiConfig/api.response.service";
import { CONSTANTS } from "../../constants";
import PageLoader from "../PageLoader/PageLoader";

const MediaGridList: React.FC<MediaGridListProps> = ({
  mediaArray = [],
  handleFetch,
  saveMedia,
  closeMediaModal,
  openMediaModal,
  mediaModalType,
  updateEditData,
}) => {
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [singleMedia, setSingleMedia] = useState<MediaObject | null>(null);

  const closeModal = () => {
    setIsModal(false);
  };

  const handleAudioDelete = (media: MediaObject) => {
    setSingleMedia(media);
    setConfirmModal(true);
  };
  const handleConfirmDelete = () => {
    API_SERVICES.MEDIA.DELETE_FILE(singleMedia?.media_id).then(data => {
      const responseData = handleResponse(data);

      if (responseData.ok) {
        setLoading(false);
        commonService.toastService(responseData.data, CONSTANTS.STATUS.SUCCESS);
        handleFetch();
        setSingleMedia(null);
      } else {
        setLoading(false);
        commonService.toastService(
          "Failed to update media",
          CONSTANTS.STATUS.FAILED
        );
      }
    });

    setConfirmModal(false);
  };

  const handleCancelDelete = () => {
    setConfirmModal(false);
    setSingleMedia(null);
  };

  const handleDelete = () => {
    setConfirmModal(true);
    setIsModal(false);
  };

  const showModal = (selectedMedia: MediaObject) => {
    setSingleMedia(selectedMedia);
    setIsModal(true);
  };
  const activeMediaProps = {
    title: "Preview",
  };
  return (
    <>
      <PageLoader isLoading={loading} />
      <CRow className="align-items-stretch">
        {mediaArray?.map(media => (
          <CCol className="mb-4" key={media.media_id} sm={4}>
            <MediaGrid
              media={media}
              onClick={() => showModal(media)}
              title={media?.title}
              handleFetch={handleFetch}
              saveMedia={saveMedia}
              closeMediaModal={closeMediaModal}
              openMediaModal={openMediaModal}
              mediaModalType={mediaModalType}
              updateEditData={updateEditData}
              onDeleteAudio={handleAudioDelete}
            />
          </CCol>
        ))}
      </CRow>

      <Modal
        activeMediaProps={activeMediaProps}
        onClose={closeModal}
        onSave={handleDelete}
        open={isModal}
        deleteButtonLabel={"Delete"}
      >
        {singleMedia?.media_type === MEDIA_TYPES.VIDEO ? (
          <video style={{ width: "100%" }} controls>
            <source src={singleMedia?.file_name} type="video/mp4" />
          </video>
        ) : (
          <CCardImage orientation="top" src={singleMedia?.file_name} />
        )}
      </Modal>

      <ConfirmModal
        title=" Are you sure you want to delete"
        confirmButtonText="Confirm"
        cancelButtonText="Cancel"
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
        isShow={confirmModal}
      />
    </>
  );
};

export default MediaGridList;
