import { toast } from "react-toastify";
import { Language, TitleError } from "../models/channel.model";
import { StaticPageState } from "../models/staticmenu.models";
import { ErrorPointProps } from "../models/bodyArea.model";
import { CONSTANTS } from "../constants";
import commonService from "../services/common.service";
import commonUtils from "./common.utils";
export const languages: Array<Language> = [
  {
    id: 1,
    code: "en",
    name: "English",
  },
  {
    id: 2,
    code: "de",
    name: "German",
  },
  {
    id: 3,
    code: "es",
    name: "Spanish",
  },
  {
    id: 4,
    code: "ru",
    name: "Russian",
  },
];

const getName = (element: string) => {
  const foundLanguage = languages.find(language => language.code === element);
  return foundLanguage?.name;
};

export const getPageContent = (data: StaticPageState) => {
  const newError: TitleError = {};
  Object.keys(data).map((element: string) => {
    if (
      data[element]?.title === "" ||
      data[element]?.title === undefined ||
      data[element]?.title === null
    ) {
      newError[getName(element) as string] = "please add a title";
    }
  });
  return newError;
};

export const ToastContent = (
  isOpenToaster: boolean,
  errors: ErrorPointProps
) => {
  if (isOpenToaster) {
    Object.keys(errors).forEach((element: string, index) => {
      if (errors[element]) {
        commonUtils.toastService(
          `${element}: ${errors[element]}`,
          CONSTANTS.TOAST_TYPE.ERROR
        );
      }
    });
  }
};
