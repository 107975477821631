import React from "react";
import {
  CNavItem,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilUser, cilBook, cilApps, cilMenu } from "@coreui/icons";
import { SideBarProps } from "../../models/layout.models";
import { useNavigate } from "react-router-dom";
const Sidebar: React.FC<SideBarProps> = ({ isOpen = true, setShowNavbar }) => {
  const navigate = useNavigate();
  return (
    <CSidebar position="fixed" visible={isOpen}>
      <CSidebarBrand className="justify-content-start">
        <img
          onClick={() => navigate("/admin/dashboard")}
          className="logo-header mx-3"
          src={"/assets/images/moa-logo-s-header.svg"}
          alt="Manual of Acupuncture logo"
        />
        MOAP ADMIN
      </CSidebarBrand>
      <CSidebarNav>
        <CNavItem onClick={() => navigate("/admin/users")} href="#">
          <CIcon customClassName="nav-icon" icon={cilUser} />
          UserList
        </CNavItem>
        <CNavItem onClick={() => navigate("/admin/static-pages")} href="#">
          <CIcon customClassName="nav-icon" icon={cilBook} />
          Static Pages
        </CNavItem>

        <CNavItem onClick={() => navigate("/admin/channels")} href="#">
          <CIcon customClassName="nav-icon" icon={cilApps} /> Channel Pages
        </CNavItem>

        <CNavItem onClick={() => navigate("/admin/media-list")} href="#">
          <CIcon customClassName="nav-icon" icon={cilMenu} /> Media Management
        </CNavItem>

        <CNavItem onClick={() => navigate("/admin/body-area")} href="#">
          <CIcon customClassName="nav-icon" icon={cilApps} /> BodyArea
        </CNavItem>

        <CNavItem onClick={() => navigate("/admin/change-password")} href="#">
          <CIcon customClassName="nav-icon" icon={cilApps} /> Change Password
        </CNavItem>
      </CSidebarNav>
      <CSidebarToggler onClick={() => setShowNavbar(!isOpen)} />
    </CSidebar>
  );
};

export default Sidebar;
