export const MESSAGE_CONSTANT = {
  CHANGE_PASSWORD_FAIL: "Unable to change password.",
  CHANGE_PASSWORD_SUCCESS: "Password changed successfully.",
  FILE_REQUIRED: "File is required.",
  FILE_SIZE_LIMIT_EXCEEDED: "The file size should not exceed 5MB.",
  INVALID_AUDIO_FILE:
    "Invalid file type for audio. Allowed types: mp3, wav, ogg.",
  INVALID_IMAGE_FILE:
    "Invalid file type for image. Allowed types: jpeg, jpg, png, gif.",
  INVALID_VIDEO_FILE:
    "Invalid file type for video. Only mp4 files are allowed.",
  TITLE_REQUIRED: "Title is required.",
  UNKNOWN_MEDIA_TYPE: "Unknown media type.",
};
