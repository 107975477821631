import React, { useEffect, useState, useRef } from "react";
import {
  CButton,
  CNavLink,
  CNav,
  CTabPane,
  CNavItem,
  CTabContent,
  CFormInput,
  CToast,
  CToastHeader,
  CToastBody,
  CToaster,
} from "@coreui/react";
import { useParams } from "react-router";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import _, { uniqueId } from "lodash";
import API_SERVICES from "../../services";
import {
  StaticPageState,
  UpdateContentFunction,
} from "../../models/staticmenu.models";
import commonService from "../../services/common.service";
import handleResponse from "../../services/apiConfig/api.response.service";
import { getChannelSubmenu } from "../../services/channel.service";
import { Language, TitleError } from "../../models/channel.model";
import Editor from "../../components/Editor/Editor";
import PageLoader from "../../components/PageLoader/PageLoader";
import { useNavigate } from "react-router-dom";
import { COMMON_CONSTANTS } from "../../constants/commonConstants";
import {
  ToastContent,
  getPageContent,
  languages,
} from "../../utils/editor.utils";
import MyEditor from "../../components/Editor/Editor";
import { CONSTANTS } from "../../constants";

const EditChannel: React.FC = () => {
  const [activeKey, setActiveKey] = useState(1);
  const [errors, setErrors] = useState<TitleError>({});
  const [editorId, setEditorId] = useState(uniqueId);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenToaster, setIsOpenToaster] = useState(false);
  const [isTitleValid, setIsTitleValid] = useState(true);
  const [isTextValid, setIsTextValid] = useState(true);
  const [language, setLanguage] = useState("en");
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [staticPage, setStaticPage] = useState<StaticPageState>({
    en: {
      title: "",
      text: "",
    },
    de: {
      title: "",
      text: "",
    },
    es: {
      title: "",
      text: "",
    },
    ru: {
      title: "",
      text: "",
    },
  });
  const [textMessage, setTextMessage] = useState("");
  const [titleMessage, setTitleMessage] = useState("");
  const [toast, showToast] = useState<string | React.ReactNode>(0);
  const toaster = useRef<HTMLDivElement | null>(null);

  const navigate = useNavigate();
  const params = useParams<{ uuid: string }>();

  useEffect(() => {
    saveChannelDetails();
  }, []);

  useEffect(() => {
    if (isOpenToaster) {
      ToastContent(isOpenToaster, errors);
      setIsOpenToaster(false);
    }
  }, [errors]);

  const handleContentChange: UpdateContentFunction = (htmlContent: string) => {
    setStaticPage(prevStaticPage => {
      const list: any = _.cloneDeep(prevStaticPage);
      list[selectedLanguage] = { ...list[selectedLanguage], text: htmlContent };
      return list;
    });
    setIsTextValid(htmlContent.trim() !== "");
    if (htmlContent.trim() === "") {
      setTextMessage("Text is required");
    } else {
      setTextMessage("");
    }
  };

  const handleSave = () => {
    setIsLoading(true);
    const test = getPageContent(staticPage);

    if (Object.keys(test).length > 0) {
      setErrors(test);
      setIsOpenToaster(true);
      setIsLoading(false);
    } else {
      const payload = {
        page_id: params.uuid as string,
        locales: staticPage,
      };
      API_SERVICES.CHANNEL.SAVE_CHANNEL_DETAILS(payload).then(data => {
        const responseData = handleResponse(data);
        if (responseData.ok) {
          commonService.toastService(responseData.data, "success");
        } else {
          commonService.toastService("Failed to update channel menu", "error");
        }
        setIsLoading(false);
      });
    }
  };

  const handleCancel = () => {
    setStaticPage(staticPage);
    setIsTitleValid(true);
    setIsTextValid(true);
    setTitleMessage("");
    setTextMessage("");
    navigate(COMMON_CONSTANTS.PREVIOUS_PAGE);
  };

  const handleTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    language: keyof StaticPageState
  ) => {
    const newTitle = event.target.value;

    setStaticPage(prevState => ({
      ...prevState,
      [language]: {
        ...prevState[language],
        title: newTitle,
      },
    }));
    selectedLanguage === "en" && setIsTitleValid(newTitle.trim() !== "");
    if (newTitle.trim() === "") {
      selectedLanguage === "en" && setTitleMessage("English title is required");
    } else {
      setTitleMessage("");
    }
  };
  const saveChannelDetails = async () => {
    const data = await getChannelSubmenu(params?.uuid as string);
    setEditorId(uniqueId);
    setStaticPage(prevStaticPage => {
      const list = _.cloneDeep(prevStaticPage);

      Object.keys(data.data).map((element: string, index: number) => {
        list[element] = data.data[element] ? data.data[element] : list[element];
      });
      return list;
    });
  };

  const updateContent: UpdateContentFunction = newContent => {
    setStaticPage(prevStaticPage => {
      const list: any = _.cloneDeep(prevStaticPage);
      list[language].text = newContent;
      return list;
    });
    setIsTextValid(newContent.trim() !== "");
    if (newContent.trim() === "") {
      setTextMessage("Text is required");
    } else {
      setTextMessage("");
    }
  };

  return (
    <>
      <CNav variant="tabs" role="tablist">
        <CNavItem role="presentation">
          <CNavLink
            active={activeKey === CONSTANTS.ACTIVE_KEY.EN}
            component="button"
            role="tab"
            aria-controls="home-tab-pane"
            aria-selected={activeKey === CONSTANTS.ACTIVE_KEY.EN}
            onClick={() => {
              setLanguage("en");

              setActiveKey(CONSTANTS.ACTIVE_KEY.EN);
            }}
          >
            English
          </CNavLink>
        </CNavItem>

        <CNavItem role="presentation">
          <CNavLink
            active={activeKey === CONSTANTS.ACTIVE_KEY.DE}
            component="button"
            role="tab"
            aria-controls="profile-tab-pane"
            aria-selected={activeKey === CONSTANTS.ACTIVE_KEY.DE}
            onClick={() => {
              setLanguage("de");

              setActiveKey(CONSTANTS.ACTIVE_KEY.DE);
            }}
          >
            German
          </CNavLink>
        </CNavItem>

        <CNavItem role="presentation">
          <CNavLink
            active={activeKey === CONSTANTS.ACTIVE_KEY.ES}
            component="button"
            role="tab"
            aria-controls="contact-tab-pane"
            aria-selected={activeKey === CONSTANTS.ACTIVE_KEY.ES}
            onClick={() => {
              setLanguage("es");

              setActiveKey(CONSTANTS.ACTIVE_KEY.ES);
            }}
          >
            Spanish
          </CNavLink>
        </CNavItem>

        <CNavItem role="presentation">
          <CNavLink
            active={activeKey === CONSTANTS.ACTIVE_KEY.RU}
            component="button"
            role="tab"
            aria-controls="contact-tab-pane"
            aria-selected={activeKey === CONSTANTS.ACTIVE_KEY.RU}
            onClick={() => {
              setLanguage("ru");

              setActiveKey(CONSTANTS.ACTIVE_KEY.RU);
            }}
          >
            Russian
          </CNavLink>
        </CNavItem>
      </CNav>
      <CToaster
        ref={toaster}
        push={toast as React.ReactElement<string>}
        placement="bottom-center"
      />
      <CTabContent>
        {Object.keys(staticPage).map(
          (element: keyof StaticPageState, index) => {
            return (
              <CTabPane
                key={index}
                role="tabpanel"
                aria-labelledby="home-tab-pane"
                visible={activeKey === Number(index) + 1}
              >
                <CFormInput
                  className="form-title"
                  type="text"
                  value={staticPage[element as keyof StaticPageState].title}
                  onChange={e =>
                    handleTitleChange(e, language as keyof StaticPageState)
                  }
                />
                {titleMessage && (
                  <div className="error-message">{titleMessage}</div>
                )}{" "}
                {staticPage ? (
                  <MyEditor
                    key={language + editorId}
                    updateContent={updateContent}
                    initialContent={
                      staticPage[element as keyof StaticPageState]
                        ? staticPage[element as keyof StaticPageState].text
                        : ""
                    }
                  />
                ) : (
                  <MyEditor
                    key="data2"
                    initialContent={""}
                    updateContent={updateContent}
                  />
                )}
                {textMessage && (
                  <div className="error-message">{textMessage}</div>
                )}{" "}
              </CTabPane>
            );
          }
        )}
      </CTabContent>
      <div className="mp-button-container">
        <CButton className="me-2" color="secondary" onClick={handleCancel}>
          Cancel
        </CButton>
        <CButton
          color="danger"
          onClick={handleSave}
          disabled={!isTitleValid || !isTextValid}
        >
          Save
        </CButton>
      </div>
      <PageLoader isLoading={isLoading} overlayOnly={false} />
    </>
  );
};

export default EditChannel;
