import React from "react";
import { CContainer, CNavbar, CNavbarToggler, CButton } from "@coreui/react";
import { NavBarProps } from "../../models/layout.models";
import { useAuth } from "../../context/userContext";
import ConfirmModal from "../../components/shared/ConfirmModal/ConfirmModal";
import commonService from "../../services/common.service";

const Navbar: React.FC<NavBarProps> = ({ ontoggleSidebar }) => {
  const { logout }: any = useAuth();
  const [showModal, setShowModal] = React.useState(false);

  const handleLogout = () => {
    setShowModal(true);
  };

  const handleConfirmLogout = () => {
    commonService.toastService(
      "You have been successfully logged out",
      "success"
    );
    logout();
    setShowModal(false);
  };

  const handleCancelLogout = () => {
    setShowModal(false);
  };

  return (
    <>
      <CNavbar colorScheme="light" className="bg-light" data-testid="navbar">
        <CContainer fluid>
          <CNavbarToggler
            aria-controls="navbarToggleExternalContent"
            aria-label="Toggle navigation"
            onClick={() => ontoggleSidebar()}
          />
          <CButton onClick={handleLogout} color="danger">
            Logout
          </CButton>
        </CContainer>
      </CNavbar>
      <ConfirmModal
        title=" Are you sure you want to logout"
        confirmButtonText="Confirm"
        cancelButtonText="Cancel"
        onConfirm={handleConfirmLogout}
        onCancel={handleCancelLogout}
        isShow={showModal}
      />
    </>
  );
};

export default Navbar;
