import { StaticMenuApiData } from "../models/staticmenu.models";
import API_URL from "./apiConfig/api.config";
import apiCall from "./apiConfig/apiCall.config";

export async function getChannelMenu() {
  return await apiCall.get(API_URL.CHANNEL.GET_CHANNEL_MENU);
}

export async function getChannelMenuPage(path: string) {
  return await apiCall.get(API_URL.CHANNEL.GET_CHANNEL_MENU_PAGE + path);
}

export async function getChannelSubmenu(path: string) {
  return await apiCall.get(API_URL.CHANNEL.GET_CHANNEL_SUBMENU + path);
}

export async function getMediaTypeChannel(type: string, media_type: string) {
  return await apiCall.get(
    API_URL.CHANNEL.GET_MEDIA_TYPE_CHANNEL + type + "/" + media_type
  );
}
export async function saveChannelMenu(data: any) {
  return await apiCall.post(API_URL.CHANNEL.SAVE_CHANNEL_MENU, data);
}

export async function saveChannelDetails(data: StaticMenuApiData) {
  return await apiCall.post(API_URL.CHANNEL.SAVE_CHANNEL_DETAILS, data);
}
