import React, { useEffect, useRef, useState } from "react";
import {
  CButton,
  CNavLink,
  CNav,
  CTabPane,
  CNavItem,
  CTabContent,
  CFormInput,
  CToast,
  CToastHeader,
  CToastBody,
  CToaster,
} from "@coreui/react";
import { useParams } from "react-router";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import API_SERVICES from "../../services";
import { StaticPageState } from "../../models/staticmenu.models";
import commonService from "../../services/common.service";
import handleResponse from "../../services/apiConfig/api.response.service";
import { getChannelMenuPage } from "../../services/channel.service";
import PageLoader from "../../components/PageLoader/PageLoader";
import { getStaticMenuPage } from "../../services/apiConfig/menu.service";
import { useNavigate } from "react-router-dom";
import { COMMON_CONSTANTS, Languages } from "../../constants/commonConstants";
import { POINT_CONSTANTS } from "../../constants/bodyAreaConstants";
import { CToasterProps } from "@coreui/react/dist/components/toast/CToaster";
import {
  ContentPayload,
  Language,
  RouteParams,
  TitleError,
} from "../../models/channel.model";
import {
  ToastContent,
  getPageContent,
  languages,
} from "../../utils/editor.utils";

const ChannelTitle: React.FC = () => {
  const [errors, setErrors] = useState<TitleError>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isTitleValid, setIsTitleValid] = useState(true);
  const [isOpenToaster, setIsOpenToaster] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [staticPage, setStaticPage] = useState<StaticPageState>({
    en: {
      title: "",
      text: "",
    },
    de: {
      title: "",
      text: "",
    },
    es: {
      title: "",
      text: "",
    },
    ru: {
      title: "",
      text: "",
    },
  });
  const [titleMessage, setTitleMessage] = useState("");
  const toaster = useRef<HTMLDivElement | null>(null);

  const navigate = useNavigate();
  const params = useParams<{ uuid: string }>();

  useEffect(() => {
    getStaticMenuDetails();
  }, []);

  useEffect(() => {
    if (isOpenToaster) {
      ToastContent(isOpenToaster, errors);
      setIsOpenToaster(false);
    }
  }, [errors]);

  const getStaticMenuDetails = async () => {
    const data = await getStaticMenuPage(params?.uuid as string);
    setStaticPage(data.data);
  };

  const handleCancel = () => {
    setStaticPage(staticPage);
    setIsTitleValid(true);
    setTitleMessage("");
    navigate(COMMON_CONSTANTS.PREVIOUS_PAGE);
  };

  const handleTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    language: keyof StaticPageState
  ) => {
    const newTitle = event.target.value;

    setStaticPage(prevState => ({
      ...prevState,
      [language]: {
        ...prevState[language],
        title: newTitle,
      },
    }));
    selectedLanguage === Languages.EN &&
      setIsTitleValid(newTitle.trim() !== "");
    if (newTitle.trim() === "" && selectedLanguage === Languages.EN) {
      setTitleMessage("English title is required");
    } else {
      setTitleMessage("");
    }
  };

  const handleOptions = () => {
    setIsLoading(true);
    const test = getPageContent(staticPage);

    if (Object.keys(test).length > 0) {
      setErrors(test);
      setIsOpenToaster(true);
      setIsLoading(false);
    } else {
      const newObj: ContentPayload = {};
      Object.keys(staticPage).forEach(languageKey => {
        newObj[languageKey] = {
          title: staticPage[languageKey].title,
          text: "dummy",
        };
      });

      const payload = {
        page_id: params.uuid as string,
        locales: newObj,
      };

      API_SERVICES.MENUS.STATIC_MENU_DETAILS(payload)
        .then((data: any) => {
          const responseData: any = handleResponse(data);
          if (responseData.ok) {
            commonService.toastService(responseData.data, "success");
          } else {
            commonService.toastService("Failed to update static menu", "error");
          }
          setIsLoading(false);
        })
        .catch(error => {
          console.error("Error saving channel menu:", error);
          commonService.toastService(
            "An error occurred while saving.",
            "error"
          );
        });
    }
  };

  return (
    <>
      <CNav variant="tabs" role="tablist">
        {languages.map((lang: any) => (
          <CNavItem role="presentation" key={lang.id}>
            <CNavLink
              active={selectedLanguage === lang.code}
              component="button"
              role="tab"
              aria-controls={`tab-${lang.code}`}
              aria-selected={selectedLanguage === lang.code}
              onClick={() => setSelectedLanguage(lang.code)}
            >
              {lang.name}
            </CNavLink>
          </CNavItem>
        ))}
      </CNav>
      <CToaster ref={toaster} placement="bottom-center" />
      <CTabContent>
        {languages.map((lang: Language) => (
          <CTabPane
            key={lang.code}
            role="tabpanel"
            aria-labelledby={`tab-${lang.code}`}
            visible={selectedLanguage === lang.code}
          >
            <CFormInput
              className="form-title"
              type="text"
              value={staticPage[lang.code]?.title || ""}
              onChange={e =>
                handleTitleChange(e, lang.code as keyof StaticPageState)
              }
            />
            {titleMessage && (
              <div className="error-message">{titleMessage}</div>
            )}
          </CTabPane>
        ))}
      </CTabContent>
      <div className="mp-button-container">
        <CButton className="me-2" color="secondary" onClick={handleCancel}>
          Cancel
        </CButton>
        <CButton
          color="danger"
          onClick={handleOptions}
          disabled={!isTitleValid}
        >
          Save
        </CButton>
      </div>
      <PageLoader isLoading={isLoading} overlayOnly={false} />
    </>
  );
};

export default ChannelTitle;
