import React, { useEffect, useState } from "react";
import {
  CButton,
  CImage,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CNav,
  CNavItem,
  CTabContent,
  CTabPane,
} from "@coreui/react";
import { CNavLink } from "@coreui/react";
import {
  BODY_AREA_CONSTANTS,
  POINT_CONSTANTS,
} from "../../constants/bodyAreaConstants";
import InteractiveImage from "../InteractiveImage/InteractiveImage";
import {
  InteractiveImageProps,
  PointPayload,
} from "../../models/bodyArea.model";
import { Area } from "../../models/channel.model";
import API_SERVICES from "../../services";
import handleResponse from "../../services/apiConfig/api.response.service";
import { CONSTANTS } from "../../constants";
import commonService from "../../services/common.service";

const InteractiveImageModal: React.FC<InteractiveImageProps> = ({
  interactiveImagePoints,
  interactiveImage,
  pointImageFlag,
  updateFlag,
}) => {
  const areas = [
    {
      key: POINT_CONSTANTS.POINT_AREA_MAP_KEY,
      value: POINT_CONSTANTS.POINT_AREA_MAP,
    },
    {
      key: BODY_AREA_CONSTANTS.INTERACTIVE_MAP_KEY,
      value: BODY_AREA_CONSTANTS.INTERACTIVE_MAP,
    },
  ];

  const [bodyAreaType, setBodyAreaType] = useState(
    POINT_CONSTANTS.POINT_AREA_MAP_KEY
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState<boolean>(false);

  const addPoint = (newPoint: PointPayload[]) => {
    API_SERVICES.POINT.SAVE_POINT_AREA(newPoint[0])
      .then(data => {
        setIsLoading(false);
        const responseData: any = handleResponse(data);
        if (responseData.ok) {
          commonService.toastService(
            responseData.data,
            CONSTANTS.STATUS.SUCCESS
          );
          updateFlag && updateFlag();
        } else {
          commonService.toastService(
            "Failed to update static menu",
            CONSTANTS.STATUS.FAILED
          );
        }
        setIsLoading(false);
      })
      .catch(error => {
        commonService.toastService("An error occurred while saving.", "error");
      });
  };

  const deletePoint = (interActivePointId: number) => {
    setIsLoading(true);
    API_SERVICES.POINT.REMOVE_POINT(interActivePointId).then(data => {
      const responseData: any = handleResponse(data);
      setIsLoading(false);
      if (responseData.ok) {
        commonService.toastService(responseData.data, CONSTANTS.STATUS.SUCCESS);
        updateFlag && updateFlag();
      } else {
        commonService.toastService(
          "Failed to update static menu",
          CONSTANTS.STATUS.FAILED
        );
      }
      setIsLoading(false);
    });
  };

  const toggleChange = (key: string) => {
    setBodyAreaType(key);
    if (key === BODY_AREA_CONSTANTS.INTERACTIVE_MAP_KEY) {
      setIsLoading(true);
    }
  };
  const imageProps = { addPoint, deletePoint };
  return (
    <>
      {" "}
      <CNav className="mb-2 mt-2" variant="tabs" role="tablist">
        {areas.map((area: Area, index: number) => (
          <CNavItem role="presentation" key={index}>
            <CNavLink
              active={bodyAreaType === area.key}
              component="button"
              role="tab"
              aria-controls={`tab-${index}`}
              aria-selected={bodyAreaType === area.key}
              onClick={() => toggleChange(area.key)}
            >
              {area.value}
            </CNavLink>
          </CNavItem>
        ))}
      </CNav>
      <CTabContent>
        {areas.map((area: Area, index: number) => (
          <CTabPane
            key={index}
            role="tabpanel"
            aria-labelledby={`tab-${index}`}
            visible={bodyAreaType === area.key}
          >
            {bodyAreaType === POINT_CONSTANTS.POINT_AREA_MAP_KEY ? (
              <CImage
                align="center"
                width={580}
                src={interactiveImage?.file_name}
              />
            ) : pointImageFlag ? (
              <InteractiveImage
                interactiveImagePoints={interactiveImagePoints}
                interactiveImage={interactiveImage}
                updateFlag={updateFlag}
                imageProps={imageProps}
              />
            ) : (
              <div className="alert alert-warning mx-auto">
                Without save interactive mage not visable
              </div>
            )}
          </CTabPane>
        ))}
      </CTabContent>
    </>
  );
};

export default InteractiveImageModal;
