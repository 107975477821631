import React, { useState, useEffect } from "react";
import { CCard, CCardImage } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {
  cilMediaPlay,
  cilMagnifyingGlass,
  cilTrash,
  cilPencil,
} from "@coreui/icons";
import { MediaGridProps } from "../../models/media.models";
import { MEDIA_TYPES } from "../../constants/commonConstants";
import MediaModal from "../MediaForm/MediaForm";
import { uniqueId } from "lodash";

const MediaGrid: React.FC<MediaGridProps> = ({
  media,
  onClick,
  title,
  openMediaModal,
  updateEditData,
  onDeleteAudio,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);

  const placeholderImage = "/assets/images/media-placeholder.png";
  const actualImage = media?.file_name;
  const actualVideoThumbnail = media?.thumbnail_url;

  const handleEdit = () => {
    openMediaModal("edit");
    updateEditData(media);
  };

  const handleIcon = () => {
    if (media && handleEdit && media.media_id) {
      handleEdit();
    }
  };
  return (
    <>
      <CCard className="h-100 pointer position-relative mp-image-grid">
        <div className="icon-wrapper mp-tools mt-2 mb-3">
          <CIcon
            className="pencil-icon mp-icon me-2"
            icon={cilPencil}
            size="lg"
            onClick={() => {
              handleIcon();
            }}
          />
          {media.media_type === MEDIA_TYPES.AUDIO && (
            <CIcon
              className="pencil-icon mp-icon me-3"
              icon={cilTrash}
              size="lg"
              onClick={() => {
                onDeleteAudio && onDeleteAudio(media);
              }}
            />
          )}
        </div>

        {media.media_type == MEDIA_TYPES.VIDEO && (
          <>
            <CCardImage
              className="image-thumbnail"
              orientation="top"
              src={
                videoLoaded
                  ? actualVideoThumbnail || placeholderImage
                  : placeholderImage
              }
              onLoad={() => setVideoLoaded(true)}
            />

            <div
              className="icon-hover hover-button"
              onClick={() => onClick?.(media)}
            >
              <CIcon
                onClick={() => onClick?.(media)}
                customClassName="nav-icon mp-icon  mp-zoom-icon"
                icon={cilMediaPlay}
              />
            </div>
          </>
        )}
        {media.media_type == MEDIA_TYPES.IMAGE && (
          <>
            <CCardImage
              className="image-thumbnail h-100"
              orientation="top"
              src={imageLoaded ? actualImage : placeholderImage}
              onLoad={() => setImageLoaded(true)}
            />
            <div
              className="icon-hover hover-button"
              onClick={() => onClick?.(media)}
            >
              <CIcon
                onClick={() => onClick?.(media)}
                customClassName="nav-icon mp-zoom-icon"
                icon={cilMagnifyingGlass}
              />
            </div>
          </>
        )}

        {media.media_type == MEDIA_TYPES.CHINESE_IMAGE && (
          <>
            <CCardImage
              className="image-thumbnail"
              orientation="top"
              src={media.thumbnail_url || media.file_name}
            />
            <div
              className="icon-hover hover-button"
              onClick={() => onClick?.(media)}
            >
              <CIcon
                customClassName="nav-icon mp-zoom-icon"
                icon={cilMagnifyingGlass}
              />
            </div>
          </>
        )}
        {media.media_type == MEDIA_TYPES.AUDIO && (
          <audio
            key={media?.file_name ?? "" + uniqueId}
            controls
            className="w-100 h-20"
          >
            <source src={media.file_name} type="audio/mp3" />
          </audio>
        )}
        <div className="media-title">{title}</div>
      </CCard>
    </>
  );
};

export default MediaGrid;
