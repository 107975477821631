enum HomeUrlActionParams {
  forgotPassword = "forgotPassword",
  login = "login",
}
export const CONSTANTS = {
  ACTIVE_KEY: {
    EN: 1,
    DE: 2,
    ES: 3,
    RU: 4,
  },
  HTTP_STATUS: {
    BAD_REQUEST: 400,
    FORBIDDEN: 403,
    INTERNAL_SERVER_ERROR: 500,
    NOT_FOUND: 404,
    SUCCESS: 200,
    UNAUTHORIZED: 401,
  },

  LOCAL_STORAGE: {
    ACCESS_TOKEN: "ACCESS_TOKEN",
    PROFILE: "PROFILE",
    REFRESH_TOKEN: "REFRESH_TOKEN",
  },

  STATUS: {
    FAILED: "failed",
    SUCCESS: "success",
  },
  TOAST_TYPE: {
    ERROR: "error",
    INFO: "info",
    SUCCESS: "success",
    WARNING: "warning",
  },
  URL_PARAMS: {
    HOME: {
      ACTION: HomeUrlActionParams,
    },
  },

  VALIDATIONS: {
    FIELD_REQUIRED: "This field is required",
  },
};
