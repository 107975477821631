import React, { Children } from "react";
import { CButton, CModal, CModalBody } from "@coreui/react";
import { ConfirmModalProps } from "../../../models/user.model";

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  title = "Are you sure want to delete this item",
  children,
  description,
  confirmButtonText = "Confirm",
  cancelButtonText = "Cancel",
  onConfirm,
  onCancel,
  isShow,
}) => {
  return (
    <div>
      <CModal visible={isShow} onClose={onCancel}>
        <CModalBody>
          <div className="app-row-padding">
            <div style={{ padding: 10, textAlign: "center" }}>
              <h6 className="st-confirm-title">{title}</h6>
            </div>
            {children && children}
            <div style={{ padding: 0, textAlign: "center" }}>
              <CButton
                className="st-cancel-btn"
                color="secondary"
                style={{ margin: 5 }}
                onClick={() => {
                  onCancel();
                }}
              >
                {cancelButtonText}
              </CButton>
              <CButton
                color="danger"
                className="st-confirm-btn"
                style={{ margin: 5 }}
                onClick={() => {
                  onConfirm();
                }}
              >
                {confirmButtonText}
              </CButton>
            </div>
          </div>
        </CModalBody>
      </CModal>
    </div>
  );
};

export default ConfirmModal;
