import React, { Children, ReactNode, useState } from "react";
import Sidebar from "../components/shared/Sidebar";
import Navbar from "../components/shared/Navbar";
import { CContainer } from "@coreui/react";
import LayoutProtected from "./LayoutProtected";

const AdminLayout = ({ children }: any) => {
  const [showNavbar, setShowNavbar] = useState<boolean>(true);
  const handleNavbar = (): void => setShowNavbar(prevState => !prevState);

  return (
    <>
      <CContainer fluid className="px-0">
        <Sidebar isOpen={showNavbar} setShowNavbar={setShowNavbar} />

        <div className="wrapper">
          <Navbar ontoggleSidebar={handleNavbar} />
          <div className="p-4">{children}</div>
        </div>
      </CContainer>
    </>
  );
};

export default AdminLayout;
