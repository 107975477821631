import API_URL from "./apiConfig/api.config";
import apiCall from "./apiConfig/apiCall.config";

export async function deleteFile(data: any) {
  return await apiCall.remove(API_URL.MEDIA.DELETE_FILE + "/" + data);
}

export async function getAllMediaType(type: string, media_type: string) {
  return await apiCall.get(
    API_URL.MEDIA.GET_ALL_MEDIA_TYPE + type + "/" + media_type
  );
}
export async function saveFileUpload(apiUrl: string, data: any) {
  return await apiCall.post(apiUrl, data);
}
