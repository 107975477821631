import React, { useState } from "react";
import { CButton, CForm } from "@coreui/react";
import API_SERVICES from "../../services";
import handleResponse from "../../services/apiConfig/api.response.service";
import commonService from "../../services/common.service";
import { useNavigate } from "react-router-dom";
import { PASSWORD_TYPE_CONSTANTS } from "../../constants/userConstants";
import ChangePasswordForm from "./ChangePasswordForm";
import { MESSAGE_CONSTANT } from "../../constants/messageConstants";
import { COMMON_CONSTANTS } from "../../constants/commonConstants";
import { URL_CONSTANT } from "../../constants/urlConstants";
const ChangePassword: React.FC = () => {
  const [confirmPassword, setConfirmPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleConfirmPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setConfirmPassword(event.target.value);
    setPasswordError("");
  };

  const isButtonDisabled = () => {
    return (
      oldPassword === "" ||
      newPassword !== confirmPassword ||
      !newPassword.match(PASSWORD_TYPE_CONSTANTS.PASSWORD_REGEX)
    );
  };

  const navigate = useNavigate();

  const handleNewPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewPassword(event.target.value);
    setPasswordError("");
  };

  const handleOldPasswordChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOldPassword(event.target.value);
    setPasswordError("");
  };

  const handleTogglePasswordVisibility = (passwordType: string) => {
    if (passwordType === PASSWORD_TYPE_CONSTANTS.OLD) {
      setShowPassword(prevShowPassword => !prevShowPassword);
    } else if (passwordType === PASSWORD_TYPE_CONSTANTS.NEW) {
      setShowNewPassword(prevShowNewPassword => !prevShowNewPassword);
    } else if (passwordType === PASSWORD_TYPE_CONSTANTS.CONFIRM) {
      setShowConfirmPassword(
        prevShowConfirmPassword => !prevShowConfirmPassword
      );
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setPasswordError("");

    API_SERVICES.USER.CHANGE_PASSWORD({
      old_password: oldPassword,
      password: newPassword,
      password_confirmation: confirmPassword,
    })
      .then(response => {
        const responseData = handleResponse(response);
        if (responseData.ok) {
          commonService.toastService(
            MESSAGE_CONSTANT.CHANGE_PASSWORD_SUCCESS,
            COMMON_CONSTANTS.SUCCESS_STATUS.SUCCESS
          );
          navigate(URL_CONSTANT.HOME_URL);
        } else {
          commonService.toastService(
            MESSAGE_CONSTANT.CHANGE_PASSWORD_FAIL,
            COMMON_CONSTANTS.SUCCESS_STATUS.ERROR
          );
        }
      })
      .catch(error => {
        commonService.toastService(
          COMMON_CONSTANTS.SUCCESS_STATUS.ERROR,
          COMMON_CONSTANTS.SUCCESS_STATUS.ERROR
        );
      });
  };

  const validatePassword = () => {
    if (!confirmPassword) return;
    if (newPassword !== confirmPassword) {
      setPasswordError("Passwords do not match");
      return;
    }

    const passwordRegex = PASSWORD_TYPE_CONSTANTS.PASSWORD_REGEX;
    if (!newPassword.match(passwordRegex)) {
      setPasswordError(
        "Password must contain at least 8 characters, including one lowercase letter, one uppercase letter, one digit, and one special character."
      );
      return;
    }
  };

  return (
    <>
      <CForm onSubmit={handleSubmit} className="w-50 mx-auto">
        <ChangePasswordForm
          label="Old Password"
          value={oldPassword}
          onChange={event => handleOldPasswordChange(event)}
          showPassword={showPassword}
          onToggleVisibility={() =>
            handleTogglePasswordVisibility(PASSWORD_TYPE_CONSTANTS.OLD)
          }
        />
        <ChangePasswordForm
          label="New Password"
          value={newPassword}
          onChange={event => handleNewPasswordChange(event)}
          onBlur={validatePassword}
          showPassword={showNewPassword}
          onToggleVisibility={() =>
            handleTogglePasswordVisibility(PASSWORD_TYPE_CONSTANTS.NEW)
          }
        />
        <ChangePasswordForm
          label="Confirm Password"
          value={confirmPassword}
          onChange={event => handleConfirmPasswordChange(event)}
          onBlur={validatePassword}
          showPassword={showConfirmPassword}
          onToggleVisibility={() =>
            handleTogglePasswordVisibility(PASSWORD_TYPE_CONSTANTS.CONFIRM)
          }
        />
        {passwordError && <div className="error-message">{passwordError}</div>}
        <div className="text-end">
          <CButton type="submit" color="danger" disabled={isButtonDisabled()}>
            Submit
          </CButton>
        </div>
      </CForm>
    </>
  );
};

export default ChangePassword;
