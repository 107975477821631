import React, { useEffect, useState } from "react";
import { getAdminUserDetail } from "../../services/user.service";
import { useNavigate, useParams } from "react-router-dom";
import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableRow,
} from "@coreui/react";

const UserDetail: React.FC<any> = ({ visible, userId, onClose }) => {
  const { uuid } = useParams();
  useEffect(() => {
    handlegetAdminUser();
  }, [userId]);
  const [users, setUsers] = useState<any>();

  const handlegetAdminUser = async () => {
    if (userId) {
      const users = await getAdminUserDetail({
        user_uuid: userId,
      });

      setUsers(users?.data);
    }
  };

  return (
    <CModal visible={visible} onClose={onClose}>
      <CModalHeader>
        <CModalTitle>User Detail</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CTable striped>
          <CTableBody>
            <CTableRow>
              <CTableDataCell>First Name</CTableDataCell>
              <CTableDataCell>{users?.first_name}</CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell>Last Name</CTableDataCell>
              <CTableDataCell>{users?.last_name}</CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell>Email</CTableDataCell>
              <CTableDataCell>{users?.email}</CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell>Language</CTableDataCell>
              <CTableDataCell>{users?.language_name}</CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell>Country</CTableDataCell>
              <CTableDataCell>{users?.country_name}</CTableDataCell>
            </CTableRow>
            <CTableRow>
              <CTableDataCell>Currency</CTableDataCell>
              <CTableDataCell>{users?.currency_name}</CTableDataCell>
            </CTableRow>
          </CTableBody>
        </CTable>
      </CModalBody>
      <CModalFooter>
        <CButton onClick={onClose} color="secondary">
          Close
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default UserDetail;
