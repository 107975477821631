import React, { ChangeEvent, useEffect, useState } from "react";
import { getAllMediaType } from "../../services/media.service";
import {
  CAlert,
  CButton,
  CFormInput,
  CFormSelect,
  CModal,
  CModalBody,
  CModalHeader,
  CNav,
  CNavItem,
  CNavLink,
  CPagination,
  CPaginationItem,
} from "@coreui/react";
import { MEDIA_GROUP, MEDIA_TYPES } from "../../constants/commonConstants";
import { MediaObject, MediaResponse } from "../../models/channel.model";
import MediaGridList from "../../components/MediaGrid/MediaGridList";
import MediaModal from "../../components/MediaForm/MediaForm";
import _, { clamp } from "lodash";
import handleResponse from "../../services/apiConfig/api.response.service";
import commonService from "../../services/common.service";
import API_SERVICES from "../../services";
import API_URL from "../../services/apiConfig/api.config";
import { CONSTANTS } from "../../constants";
import PageLoader from "../../components/PageLoader/PageLoader";
import { POINT_CONSTANTS } from "../../constants/bodyAreaConstants";

const MediaList: React.FC = () => {
  const [activeMediaTab, setActiveMediaTab] = useState<MEDIA_TYPES>(
    MEDIA_TYPES.IMAGE
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [fetched, setFetched] = useState<boolean>(false);

  const navItemsArray = [
    {
      id: 0,
      name: "Image",
      value: MEDIA_TYPES.IMAGE,
    },
    {
      id: 1,
      name: "Video",
      value: MEDIA_TYPES.VIDEO,
    },
    {
      id: 2,
      name: "Audio",
      value: MEDIA_TYPES.AUDIO,
    },
    {
      id: 3,
      name: "Chinese Image",
      value: MEDIA_TYPES.CHINESE_IMAGE,
    },
  ];
  const [currentPage, setCurrentPage] = useState(1);

  const [editData, setEditData] = useState({});

  const [mediaItems, setMediaItems] = useState<MediaObject[]>([]);

  const [selectMediaGroup, setSelectMediaGroup] = useState(MEDIA_GROUP.POINT);

  const [mediaModalType, setMediaModalType] = useState("");

  const [searchQuery, setSearchQuery] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const [navMenu, setNavMenu] = useState<any[]>(navItemsArray);

  const itemsPerPage = 9;
  let debounceTimer: any;
  useEffect(() => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      handleFetch();
    }, 1000);
    return () => {
      clearTimeout(debounceTimer);
    };
  }, [searchQuery, selectMediaGroup, activeMediaTab]);

  useEffect(() => {
    navMenuItems(selectMediaGroup);
  }, [selectMediaGroup]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery, selectMediaGroup, activeMediaTab]);

  const closeMediaModal = (modalType: string) => {
    setMediaModalType(modalType);
  };

  const handleFetch = async () => {
    setIsFetching(true);
    setFetched(true);
    setLoading(true);
    const result = await getAllMediaType(selectMediaGroup, activeMediaTab);
    const mediaData = result.data;
    const sortedData = _.sortBy(mediaData, "media_id").reverse();
    const filteredMedia = sortedData.filter(
      (media: MediaObject) =>
        media.title &&
        media.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setMediaItems(filteredMedia);
    setIsFetching(false);
    setLoading(false);
  };

  const handleMediaGroupChange = (event: any) => {
    const selectedGp = event.target.value;
    if (selectedGp === MEDIA_GROUP.CHANNEL) {
      setActiveMediaTab(MEDIA_TYPES.VIDEO);
    } else if (selectedGp === MEDIA_GROUP.BODY_AREA) {
      setActiveMediaTab(MEDIA_TYPES.IMAGE);
    }
    setSelectMediaGroup(event.target.value);
  };

  const handleMediaTabChange = (activeTab: MEDIA_TYPES) => {
    setActiveMediaTab(activeTab);
  };

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    const totalPages = Math.ceil(mediaItems?.length / itemsPerPage);
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleNewMedia = (media: MediaResponse) => {
    if (media?.details?.media_type == activeMediaTab) {
      handleFetch();
      closeMediaModal("");
    }
  };
  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const openMediaModal = (modalType: string) => {
    setMediaModalType(modalType);
  };

  const updateEditData = (editData: object) => {
    setEditData(editData);
  };

  const saveMedia = (formData: FormData, editData: MediaObject) => {
    let apiUrl = "";
    if (editData.media_id === undefined) {
      apiUrl = API_URL.MEDIA.SAVE_FILE_UPLOAD;
    } else {
      apiUrl = API_URL.MEDIA.UPDATE_FILE;
    }
    setLoading(true);
    API_SERVICES.MEDIA.SAVE_FILE_UPLOAD(apiUrl, formData).then(data => {
      const responseData = handleResponse(data);
      if (responseData.ok) {
        setLoading(false);
        commonService.toastService(
          responseData?.data?.message,
          CONSTANTS.STATUS.SUCCESS
        );
        setEditData({});
        handleNewMedia?.(responseData.data);
      } else {
        setLoading(false);
        commonService.toastService("Failed to update media", "error");
      }
    });
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const mediaItemsForPage = mediaItems?.slice(startIndex, endIndex);
  const totalPages = Math.ceil(mediaItems?.length / itemsPerPage);
  const pageRange = Array.from(
    { length: Math.min(totalPages, 5) },
    (_, index) => index + currentPage - 2
  ).filter(page => page >= 1 && page <= totalPages);
  const navMenuItems = (mediaGrp: MEDIA_GROUP) => {
    switch (mediaGrp) {
      case MEDIA_GROUP.CHANNEL:
        setNavMenu([navItemsArray[1]]);
        break;
      case MEDIA_GROUP.BODY_AREA:
        setNavMenu([navItemsArray[0]]);
        break;
      default:
        setNavMenu(navItemsArray);
        break;
    }
  };
  return (
    <>
      {loading ? (
        <PageLoader isLoading={loading} />
      ) : (
        <>
          <div className="d-flex justify-content-between">
            <CFormSelect
              className="form-select mb-2"
              value={selectMediaGroup}
              onChange={handleMediaGroupChange}
            >
              <option value={MEDIA_GROUP.POINT}>
                {MEDIA_GROUP.POINT.toLocaleUpperCase()}
              </option>
              <option value={MEDIA_GROUP.CHANNEL}>
                {MEDIA_GROUP.CHANNEL.toLocaleUpperCase()}
              </option>
              <option value={MEDIA_GROUP.BODY_AREA}>
                {MEDIA_GROUP.BODY_AREA.toLocaleUpperCase()}
              </option>
            </CFormSelect>

            <CButton
              onClick={() => {
                openMediaModal("add");
                setEditData({ media_type: activeMediaTab });
              }}
              color="primary"
            >
              Add Media
            </CButton>
          </div>
          <CModal
            visible={mediaModalType === "add" || mediaModalType === "edit"}
            onClose={() => {
              closeMediaModal("");
            }}
          >
            <CModalHeader>
              {mediaModalType === "add" ? "Add Media" : "Edit Media"}
            </CModalHeader>
            <CModalBody>
              <MediaModal
                onClose={() => {
                  closeMediaModal("");
                }}
                type={selectMediaGroup}
                onFileUpload={handleNewMedia}
                saveMedia={saveMedia}
                editData={editData}
                activeMediaTab={activeMediaTab}
              />
            </CModalBody>
          </CModal>
          <CNav className="mb-3" variant="tabs" role="tablist">
            {navMenu.map(menu => (
              <CNavItem key={menu.id}>
                <CNavLink
                  href="#"
                  className={
                    activeMediaTab.toLocaleLowerCase() ===
                    menu.value.toLocaleLowerCase()
                      ? "active"
                      : ""
                  }
                  onClick={handleMediaTabChange.bind(this, menu.value)}
                >
                  {menu.name}
                </CNavLink>
              </CNavItem>
            ))}
          </CNav>
          <div className="d-flex justify-content-end mb-2">
            <CFormInput
              className="w-auto"
              type="text"
              placeholder="Search by title..."
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
          </div>
          {mediaItems.length > POINT_CONSTANTS.IMAGE_INDEX_ZERO ? (
            <MediaGridList
              mediaArray={mediaItemsForPage}
              handleFetch={handleFetch}
              saveMedia={saveMedia}
              closeMediaModal={closeMediaModal}
              openMediaModal={openMediaModal}
              mediaModalType={mediaModalType}
              updateEditData={updateEditData}
            />
          ) : (
            fetched && (
              <CAlert color="info" className="text-center mb-3">
                No results found.
              </CAlert>
            )
          )}

          <CPagination align="end" aria-label="Page navigation example">
            {currentPage > POINT_CONSTANTS.IMAGE_INDEX_ONE && (
              <CPaginationItem
                className="mp-cursor-pointer"
                onClick={handlePreviousPage}
              >
                Previous
              </CPaginationItem>
            )}
            {pageRange.map(pageNumber => (
              <CPaginationItem
                key={pageNumber}
                onClick={() => handlePageChange(pageNumber)}
                active={currentPage === pageNumber}
                className="pagination-item"
              >
                {pageNumber}
              </CPaginationItem>
            ))}
            {currentPage < totalPages && (
              <CPaginationItem
                className="mp-cursor-pointer"
                onClick={handleNextPage}
              >
                Next
              </CPaginationItem>
            )}
          </CPagination>
        </>
      )}
    </>
  );
};

export default MediaList;
