import {
  getChannelMenu,
  getChannelMenuPage,
  getChannelSubmenu,
  getMediaTypeChannel,
  saveChannelDetails,
  saveChannelMenu,
} from "./channel.service";

import {
  getStaticMenu,
  getStaticMenuDetails,
  getStaticMenuPage,
} from "./apiConfig/menu.service";

import {
  getChannelPointPage,
  getChannelPoints,
  getMediaType,
  removePoint,
  saveChannelPoint,
  savePointArea,
} from "./point.service";
import {
  adminLogin,
  adminLogout,
  changePassword,
  changeSubscribe,
  getAdminUserDetail,
  getAdminUsers,
} from "./user.service";
import { deleteBodyArea, getBodyPoint, saveBodyArea } from "./bodyArea.service";

import { deleteFile, getAllMediaType, saveFileUpload } from "./media.service";
const API_SERVICES = {
  BODY_AREA: {
    GET_BODY_POINT: getBodyPoint,
    REMOVE_BODY_AREA: deleteBodyArea,
    SAVE_BODY_AREA: saveBodyArea,
  },

  CHANNEL: {
    GET_CHANNEL_MENU: getChannelMenu,
    GET_CHANNEL_SUBMENU: getChannelSubmenu,
    GET_CHANNELS_MENU_PAGE: getChannelMenuPage,
    GET_MEDIA_TYPE_CHANNEL: getMediaTypeChannel,
    SAVE_CHANNEL_DETAILS: saveChannelDetails,
    SAVE_CHANNEL_MENU: saveChannelMenu,
  },

  MENUS: {
    STATIC_MENU_DETAILS: getStaticMenuDetails,
    STATIC_MENU_PAGE: getStaticMenuPage,
    STATIC_MENUS: getStaticMenu,
  },

  MEDIA: {
    DELETE_FILE: deleteFile,
    GET_ALL_MEDIA_TYPE: getAllMediaType,
    SAVE_FILE_UPLOAD: saveFileUpload,
  },

  POINT: {
    GET_CHANNEL_POINT_PAGE: getChannelPointPage,
    GET_CHANNEL_POINTS: getChannelPoints,
    GET_MEDIA_TYPE: getMediaType,
    REMOVE_POINT: removePoint,
    SAVE_CHANNEL_POINT: saveChannelPoint,
    SAVE_POINT_AREA: savePointArea,
  },

  USER: {
    ADMIN_GET_ALL_USERS: getAdminUsers,
    ADMIN_GET_USER_DETAILS: getAdminUserDetail,
    ADMIN_LOGIN: adminLogin,
    ADMIN_LOGOUT: adminLogout,
    CHANGE_PASSWORD: changePassword,
    CHANGE_SUBSCRIBE: changeSubscribe,
  },
};

export default API_SERVICES;
