import React, { useEffect, useState } from "react";
import {
  CButton,
  CNavLink,
  CNav,
  CTabPane,
  CNavItem,
  CTabContent,
  CFormInput,
  CCardImage,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CRow,
  CCol,
  CImage,
  CInputGroup,
  CFormSelect,
  CPopover,
} from "@coreui/react";
import { useParams } from "react-router";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from "draft-js";
import API_SERVICES from "../../services";
import { StaticPageState } from "../../models/staticmenu.models";
import commonService from "../../services/common.service";
import handleResponse from "../../services/apiConfig/api.response.service";
import {
  getChannelMenuPage,
  getMediaTypeChannel,
} from "../../services/channel.service";
import PageLoader from "../../components/PageLoader/PageLoader";
import { useNavigate } from "react-router-dom";
import {
  COMMON_CONSTANTS,
  MEDIA_TYPES,
  MEDIA_TYPES_CHANNELS,
} from "../../constants/commonConstants";
import VideoCard from "../../components/VideoCard/VideoCard";
import _, { uniqueId } from "lodash";
import { CONSTANTS } from "../../constants";
import { getBodyPoint } from "../../services/bodyArea.service";
import BodyAreaMap from "../../components/BodyAreaMap/BodyAreaMap";
import { getMediaType } from "../../services/point.service";
import ConfirmModal from "../../components/shared/ConfirmModal/ConfirmModal";
import { BODY_AREA_CONSTANTS } from "../../constants/bodyAreaConstants";
import {
  BodyArea,
  BodyAreaData,
  BodyAreaDataPayload,
  ErrorProps,
  Language,
  LocalesPayload,
  LocalesProps,
  PointPayload,
} from "../../models/bodyArea.model";
import { PointObject } from "../../models/channel.model";
import { CToast } from "@coreui/react";
import { CToastBody } from "@coreui/react";
import InteractiveImage from "../../components/InteractiveImage/InteractiveImage";
// Assuming prevMediaData has a specific structure like this

const BodyPoint: React.FC = () => {
  const [allImages, setAllImages] = useState<BodyArea[]>([]);
  const [allMediaData, setAllMediaData] = useState<BodyAreaData | null>();
  const [bodyAreaType, setBodyAreaType] = useState(
    BODY_AREA_CONSTANTS.BODY_AREA_MAP_KEY
  );
  const [confirmModal, setConfirmModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<ErrorProps>({});
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [interActiveImage, setInterActiveImage] = useState<BodyArea>();
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [title, setTitle] = useState("");
  const [titleError, setTitleError] = useState(false);
  const navigate = useNavigate();
  const { area } = useParams();
  const areas = [
    {
      key: BODY_AREA_CONSTANTS.BODY_AREA_MAP_KEY,
      value: BODY_AREA_CONSTANTS.BODY_AREA_MAP,
    },
    {
      key: BODY_AREA_CONSTANTS.INTERACTIVE_MAP_KEY,
      value: BODY_AREA_CONSTANTS.INTERACTIVE_MAP,
    },
  ];
  const languages: Array<Language> = [
    {
      id: 1,
      code: "en",
      name: "English",
    },
    {
      id: 2,
      code: "de",
      name: "German",
    },
    {
      id: 3,
      code: "es",
      name: "Spanish",
    },
    {
      id: 4,
      code: "ru",
      name: "Russian",
    },
  ];

  useEffect(() => {
    fetchBodyPoint();
    fetchAllBodyAreaImage();
  }, []);

  const addPoint = (point: PointPayload[]) => {
    const payload: BodyAreaDataPayload = {
      body_area_id: allMediaData?.points.body_area_id ?? 0,
      intractive_image_id: allMediaData?.points.interactive_image.media_id ?? 0,
      point: point[0],
      edit_flag: BODY_AREA_CONSTANTS.BODY_IMAGE_FLAG,
    };
    saveBodyArea(payload);
  };

  const deletePoint = (pointId: number) => {
    setIsLoading(true);
    API_SERVICES.BODY_AREA.REMOVE_BODY_AREA(pointId).then((data: any) => {
      const responseData: any = handleResponse(data);
      if (responseData.ok) {
        commonService.toastService(responseData.data, CONSTANTS.STATUS.SUCCESS);
        fetchBodyPoint();
      } else {
        commonService.toastService(
          "Failed to update static menu",
          CONSTANTS.STATUS.FAILED
        );
      }
      setIsLoading(false);
    });
  };

  const fetchAllBodyAreaImage = async () => {
    const allImages = await getMediaType(
      BODY_AREA_CONSTANTS.BODY_AREA_TYPE,
      MEDIA_TYPES.IMAGE
    );
    setAllImages(allImages?.data);
  };

  const fetchBodyPoint = async () => {
    const points = await getBodyPoint(area);
    setAllMediaData({
      points: points.data,
    });
  };
  const getName = (element: string) => {
    const foundLanguage = languages.find(language => language.code === element);
    return foundLanguage?.name;
  };
  const getErrorMessage = async (payload: BodyAreaDataPayload) => {
    const newErrorMessage: ErrorProps = {};
    await languages.forEach(lang => {
      if (
        payload?.locales &&
        payload?.locales[lang.code] &&
        !payload?.locales[lang.code]?.body_area_image_id
      ) {
        newErrorMessage[lang.code] = newErrorMessage[lang.code]
          ? { ...newErrorMessage[lang.code], image: "Please select an image" }
          : { image: "Please select an image" };
      }
      if (
        payload?.locales &&
        payload?.locales[lang.code] &&
        !payload?.locales[lang.code]?.name
      ) {
        newErrorMessage[lang.code] = newErrorMessage[lang.code]
          ? { ...newErrorMessage[lang.code], title: "Please add a title" }
          : { title: "Please add a title" };
      }
      if (
        payload?.locales &&
        payload.locales[lang.code] &&
        payload.locales[lang.code].name &&
        payload.locales[lang.code].body_area_image_id
      ) {
        delete newErrorMessage[lang.code];
      }
    });
    return newErrorMessage;
  };
  const handleOnChange = (value: number) => {
    const currentImageData = allImages?.find(
      (imageData: BodyArea) => imageData?.media_id == value
    );
    setAllMediaData((prevMediaData: any) => {
      const newMediaData = { ...prevMediaData };
      if (newMediaData.points.locales[selectedLanguage]?.body_area_image) {
        newMediaData.points.locales[
          selectedLanguage
        ].body_area_image.file_name = currentImageData?.file_name;
        newMediaData.points.locales[selectedLanguage].body_area_image.media_id =
          currentImageData?.media_id;
      } else if (newMediaData.points.locales[selectedLanguage]) {
        newMediaData.points.locales[selectedLanguage] = {
          ...newMediaData.points.locales[selectedLanguage],
          body_area_image: [
            {
              file_name: currentImageData?.file_name,
              media_id: currentImageData?.media_id,
            },
          ],
        };
      } else {
        newMediaData.points.locales[selectedLanguage] = {
          body_area_image: [
            {
              file_name: currentImageData?.file_name,
              media_id: currentImageData?.media_id,
            },
          ],
        };
      }
      return newMediaData;
    });
  };

  const handleOnChangeInteractiveImage = (value: number) => {
    setConfirmModal(true);
    const currentImageData = allImages?.find(
      (imageData: any) => imageData?.media_id == value
    );
    setInterActiveImage(currentImageData);
  };

  const saveBodyArea = async (payload: BodyAreaDataPayload) => {
    const errors = await getErrorMessage(payload);
    if (Object.keys(errors).length > 0) {
      setErrorMessage(errors);
      setIsOpen(true);
    } else {
      saveBodyAreaData(payload);
    }
  };
  const saveBodyAreaData = (payload: BodyAreaDataPayload) => {
    API_SERVICES.BODY_AREA.SAVE_BODY_AREA(payload)
      .then((data: any) => {
        const responseData: any = handleResponse(data);
        if (responseData.ok) {
          commonService.toastService(
            responseData.data,
            CONSTANTS.STATUS.SUCCESS
          );
          fetchBodyPoint();
        } else {
          commonService.toastService(
            "Failed to update static menu",
            CONSTANTS.STATUS.FAILED
          );
        }
        setIsLoading(false);
      })
      .catch(error => {
        commonService.toastService("An error occurred while saving.", "error");
      });
  };
  const saveBodyAreaImage = () => {
    const locales = {} as LocalesProps;

    languages.map(lang => {
      const bodyAreaInfo = allMediaData?.points.locales[lang.code];

      if (bodyAreaInfo && bodyAreaInfo.body_area_image) {
        const firstImage = bodyAreaInfo.body_area_image;

        locales[lang.code] = {
          name: bodyAreaInfo.name,
          body_area_image_id: firstImage?.media_id,
        };
      } else if (bodyAreaInfo) {
        locales[lang.code] = {
          name: bodyAreaInfo.name,
          body_area_image_id: null,
        };
      }
    });
    const payload: BodyAreaDataPayload = {
      body_area_id: allMediaData?.points.body_area_id ?? 0,
      intractive_image_id: allMediaData?.points.interactive_image.media_id ?? 0,
      edit_flag: BODY_AREA_CONSTANTS.BODY_IMAGE_FLAG,
      locales: locales,
    };
    saveBodyArea(payload);
  };

  const saveInterActiveImage = () => {
    const payload: BodyAreaDataPayload = {
      body_area_id: allMediaData?.points.body_area_id ?? 0,
      intractive_image_id: interActiveImage?.media_id ?? 0,
      edit_flag: BODY_AREA_CONSTANTS.INTERACTIVE_IMAGE_FLAG,
    };
    saveBodyArea(payload);
    setConfirmModal(false);
  };

  const titleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.value) {
      setTitleError(true);
    } else {
      setTitleError(false);
    }
    setAllMediaData((prevMediaData: BodyAreaData | null | undefined) => {
      if (!prevMediaData) {
        return null;
      }

      const newMediaData = { ...prevMediaData };

      if (newMediaData.points) {
        const selectedLanguageData =
          newMediaData.points.locales[selectedLanguage];

        if (selectedLanguageData) {
          selectedLanguageData.name = event.target.value;
        } else {
          newMediaData.points.locales[selectedLanguage] = {
            name: event.target.value,
          };
        }

        newMediaData.points.locales = { ...newMediaData.points.locales };
      }

      return newMediaData;
    });
  };
  const toggleChange = (key: string) => {
    setBodyAreaType(key);
    if (key === BODY_AREA_CONSTANTS.INTERACTIVE_MAP_KEY) {
      setIsLoading(true);
    }
  };
  const updateLoader = () => {
    setIsLoading(false);
  };
  const loaderProps = { isLoading, updateLoader };
  const imageProps = { addPoint, deletePoint };
  return (
    <>
      <CToast
        className="mx-auto"
        visible={isOpen}
        color="danger"
        onClose={() => setIsOpen(false)}
      >
        <CToastBody>
          {Object.keys(errorMessage).map((element: any, index) => (
            <div key={index}>
              <div>
                {errorMessage[element] && errorMessage[element].title
                  ? getName(element) + ":" + errorMessage[element].title
                  : ""}
              </div>
              <div>
                {errorMessage[element] && errorMessage[element].image
                  ? getName(element) + ":" + errorMessage[element].image
                  : ""}
              </div>
            </div>
          ))}
        </CToastBody>
      </CToast>
      <ConfirmModal
        title="Are you certain you want to update the image? It will eliminate all existing points!"
        confirmButtonText="Confirm"
        cancelButtonText="Cancel"
        onConfirm={() => {
          saveInterActiveImage();
        }}
        onCancel={() => {
          setConfirmModal(false);
        }}
        isShow={confirmModal}
      >
        <CImage
          className="d-block w-25 mx-auto"
          src={interActiveImage?.file_name}
          alt=""
        />
      </ConfirmModal>
      <>
        <PageLoader isLoading={isLoading} overlayOnly={false} />
        <CNav className="mb-2" variant="tabs" role="tablist">
          {areas.map((area: any, index: number) => (
            <CNavItem role="presentation" key={index}>
              <CNavLink
                active={bodyAreaType === area.key}
                component="button"
                role="tab"
                aria-controls={`tab-${index}`}
                aria-selected={bodyAreaType === area.key}
                onClick={() => toggleChange(area.key)}
              >
                {area.value}
              </CNavLink>
            </CNavItem>
          ))}
        </CNav>{" "}
        <CTabContent>
          {areas.map((area: any, index: number) => (
            <CTabPane
              key={index}
              role="tabpanel"
              aria-labelledby={`tab-${index}`}
              visible={bodyAreaType === area.key}
            >
              {bodyAreaType === BODY_AREA_CONSTANTS.BODY_AREA_MAP_KEY && (
                <>
                  <CNav className="mb-2" variant="tabs" role="tablist">
                    {languages.map((lang: any) => (
                      <CNavItem role="presentation" key={lang.id}>
                        <CNavLink
                          active={selectedLanguage === lang.code}
                          component="button"
                          role="tab"
                          aria-controls={`tab-${lang.code}`}
                          aria-selected={selectedLanguage === lang.code}
                          onClick={() => setSelectedLanguage(lang.code)}
                        >
                          {lang.name}
                        </CNavLink>
                      </CNavItem>
                    ))}
                  </CNav>
                  <CTabContent>
                    {languages.map((lang: any) => (
                      <>
                        <CTabPane
                          key={lang.code}
                          role="tabpanel"
                          aria-labelledby={`tab-${lang.code}`}
                          visible={selectedLanguage === lang.code}
                        >
                          <div className="mt-2">
                            <div className="w-100 mb-4 d-md-flex justify-content-between">
                              <div>
                                <label>
                                  Title {<span className="text-danger">*</span>}
                                </label>{" "}
                                <CFormInput
                                  type="text"
                                  value={
                                    allMediaData?.points?.locales[lang.code]
                                      ? allMediaData?.points?.locales[lang.code]
                                          .name
                                      : ""
                                  }
                                  onChange={e => {
                                    titleOnChange(e);
                                  }}
                                />{" "}
                              </div>
                              <div>
                                <label>Please select an image</label>
                                <CInputGroup>
                                  <CFormSelect
                                    id="mediaSelect"
                                    onChange={e => {
                                      handleOnChange(Number(e.target.value));
                                    }}
                                    value={
                                      allMediaData?.points?.locales?.[lang.code]
                                        ?.body_area_image?.media_id || ""
                                    }
                                  >
                                    <option value="" disabled>
                                      Please select an image
                                    </option>
                                    {allImages?.map((imageData: any) => (
                                      <option
                                        key={imageData?.media_id}
                                        value={imageData?.media_id}
                                      >
                                        {imageData?.title}
                                      </option>
                                    ))}
                                  </CFormSelect>
                                </CInputGroup>
                              </div>
                            </div>
                            {allMediaData?.points?.locales?.[lang.code]
                              ?.body_area_image?.file_name && (
                              <CImage
                                className="d-block  mx-auto mp-body-image"
                                src={
                                  allMediaData.points.locales[lang.code]
                                    ?.body_area_image?.file_name
                                }
                                alt=""
                              />
                            )}
                          </div>
                        </CTabPane>
                      </>
                    ))}
                  </CTabContent>
                </>
              )}

              {bodyAreaType === BODY_AREA_CONSTANTS.INTERACTIVE_MAP_KEY && (
                <>
                  <div className="col-sm-6 col-lg-4">
                    <label>Please select an image</label>
                    <CInputGroup>
                      <CFormSelect
                        id="mediaSelect"
                        onChange={e => {
                          handleOnChangeInteractiveImage(
                            Number(e.target.value)
                          );
                        }}
                        value={
                          allMediaData?.points?.interactive_image?.media_id ||
                          ""
                        }
                      >
                        <option value="" selected disabled>
                          Please select an image
                        </option>
                        {allImages?.map((imageData: any) => (
                          <option
                            key={imageData?.media_id}
                            value={imageData?.media_id}
                          >
                            {imageData?.title}
                          </option>
                        ))}
                      </CFormSelect>
                    </CInputGroup>
                  </div>

                  <InteractiveImage
                    interactiveImage={
                      allMediaData?.points?.interactive_image
                        ? allMediaData?.points?.interactive_image
                        : null
                    }
                    interactiveImagePoints={
                      allMediaData?.points?.points
                        ? allMediaData?.points?.points
                        : []
                    }
                    loaderProps={loaderProps}
                    imageProps={imageProps}
                  />
                </>
              )}
            </CTabPane>
          ))}
        </CTabContent>
        <div className="d-flex justify-content-end mt-4">
          {bodyAreaType !== BODY_AREA_CONSTANTS.INTERACTIVE_MAP_KEY && (
            <div className="mp-button-container ">
              <CButton
                className="me-2 "
                color="secondary"
                onClick={() => {
                  navigate("/admin/body-area");
                }}
              >
                Cancel
              </CButton>
              <CButton
                color="danger"
                onClick={() => {
                  saveBodyAreaImage();
                }}
              >
                Save
              </CButton>
            </div>
          )}
        </div>
      </>
    </>
  );
};

export default BodyPoint;
