import API_URL from "./apiConfig/api.config";
import apiCall from "./apiConfig/apiCall.config";

export async function getChannelPoints() {
  return await apiCall.get(API_URL.POINT.GET_CHANNEL_POINT);
}
export async function getChannelPointPage(path: string) {
  return await apiCall.get(API_URL.POINT.GET_CHANNEL_POINT_PAGE + path);
}

export async function getMediaType(type: string, media_type: string) {
  return await apiCall.get(
    API_URL.POINT.GET_MEDIA_TYPE + type + "/" + media_type
  );
}
export async function removePoint(id: any) {
  return await apiCall.remove(API_URL.POINT.REMOVE_POINT_AREA + "/" + id);
}

export async function saveChannelPoint(data: any) {
  return await apiCall.post(API_URL.POINT.SAVE_CHANNEL_POINT, data);
}
export async function savePointArea(data: any) {
  return await apiCall.post(API_URL.POINT.SAVE_POINT_AREA, data);
}
