import { StaticMenuApiData } from "../../models/staticmenu.models";
import API_URL from "./api.config";
import apiCall from "./apiCall.config";

export async function getStaticMenu() {
  return await apiCall.get(API_URL.MENUS.STATIC_MENUS);
}
export async function getStaticMenuDetails(data: StaticMenuApiData) {
  return await apiCall.post(API_URL.MENUS.STATIC_MENU_DETAILS, data);
}
export async function getStaticMenuPage(path: string) {
  return await apiCall.get(API_URL.MENUS.STATIC_MENU_PAGE + path);
}
