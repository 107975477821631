import React, { useMemo, useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { UserListProps } from "../../../models/user.model";
import { USER_CONSTANTS } from "../../../constants/userConstants";
import { CAlert, CButton } from "@coreui/react";
import { useNavigate } from "react-router-dom";

const UserList: React.FC<UserListProps> = ({ rowData, columnDefs }) => {
  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const defaultColDef = useMemo(() => {
    return {
      width: 170,
      sortable: true,
      flex: 1,
      minWidth: 150,
      filter: "agTextColumnFilter",
      unSortIcon: true,
    };
  }, []);
  const gridOptions = {
    pagination: true,
    quickFilterText: "",
    enableFilter: false,
  };
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const pageCountOptions = USER_CONSTANTS.PAGE_COUNT_LIST;
  const [detailsFound, setDetailsFound] = useState(true);
  const [pageCount, setPageCount] = useState(10);
  const [quickFilterText, setQuickFilterText] = useState("");
  const [onlyProShow, setOnlyProShow] = useState(false);
  const [row, setRow] = useState<any>([]);
  useEffect(() => {
    setRow(rowData);
  }, [rowData]);

  const showProUsers = () => {
    const filteredData = rowData.filter(
      item => item.plan_purchased_id === USER_CONSTANTS.PRO_ID
    );
    setDetailsFound(filteredData.length > 0);
    if (onlyProShow) {
      setOnlyProShow(!onlyProShow);
      setRow(rowData);
    } else {
      setOnlyProShow(!onlyProShow);
      setRow(filteredData);
    }
  };
  const handlePageCountChange = (event: any) => {
    const newPageCount = parseInt(event.target.value, 10);
    setPageCount(newPageCount);
  };
  const handleQuickFilterChange = (event: any) => {
    const newQuickFilterText = event.target.value;
    setQuickFilterText(newQuickFilterText);

    const filteredData = rowData.filter(row =>
      columnDefs.some(colDef =>
        String(row[colDef.field])
          .toLowerCase()
          .includes(newQuickFilterText.trim().toLowerCase())
      )
    );
    setDetailsFound(filteredData.length > 0);
  };

  const navigate = useNavigate();
  return (
    <div style={containerStyle}>
      <div className="d-flex justify-content-between align-items-center">
        <div className="page-size d-flex mb-2">
          <div>Entries:</div>
          <select id="pageCount" onChange={handlePageCountChange}>
            {pageCountOptions.map(count => (
              <option key={count} value={count}>
                {count}
              </option>
            ))}
          </select>
          <button
            className="ms-3 btn btn-primary"
            onClick={() => showProUsers()}
          >
            {onlyProShow ? "Show all users" : "Show Pro users"}
          </button>
        </div>
        <div className="quick-filter mb-2">
          <input
            type="text"
            placeholder="Search menu..."
            value={quickFilterText}
            onChange={handleQuickFilterChange}
          />
        </div>
      </div>
      {detailsFound ? (
        <div style={gridStyle} className="ag-theme-alpine">
          <AgGridReact
            rowData={row}
            columnDefs={columnDefs}
            domLayout="autoHeight"
            defaultColDef={defaultColDef}
            gridOptions={gridOptions}
            paginationPageSize={pageCount}
            quickFilterText={quickFilterText}
          />
        </div>
      ) : (
        <CAlert color={"info"} className="no-details-found text-center">
          No details found.
        </CAlert>
      )}
    </div>
  );
};

export default UserList;
