/* eslint-disable no-debugger */
import React, { useEffect, useRef, useState } from "react";
import {
  CButton,
  CNavLink,
  CNav,
  CTabPane,
  CNavItem,
  CTabContent,
  CFormInput,
  CCol,
  CRow,
  CCardImage,
  CAccordionItem,
  CAccordionHeader,
  CAccordion,
  CAccordionBody,
  CToastBody,
  CToast,
  CFormLabel,
  CCard,
  CCardTitle,
  CCardBody,
  CImage,
  CFormCheck,
  CTable,
  CTableRow,
  CTableHeaderCell,
  CTableBody,
  CTableDataCell,
  CToaster,
  CToastHeader,
} from "@coreui/react";
import { Params, useParams } from "react-router";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import _, { uniqueId } from "lodash";
import API_SERVICES from "../../services";
import {
  StaticPageState,
  UpdateContentFunction,
} from "../../models/staticmenu.models";
import commonService from "../../services/common.service";
import handleResponse from "../../services/apiConfig/api.response.service";
import {
  Content,
  Language,
  MediaObject,
  MediaOriginalDataProps,
  PageDataType,
  PointDataProps,
} from "../../models/channel.model";
import Editor from "../../components/Editor/Editor";
import PageLoader from "../../components/PageLoader/PageLoader";
import { useNavigate } from "react-router-dom";
import {
  COMMON_CONSTANTS,
  MEDIA_TYPES,
  POINT_CONSTANTS,
} from "../../constants/commonConstants";
import {
  getChannelPointPage,
  getMediaType,
} from "../../services/point.service";
import Modal from "../../components/coreui/Modal";
import MediaCard from "../../components/MediaCard/MediaCard";
import { CONSTANTS } from "../../constants";
import { ErrorPointProps, ErrorProps } from "../../models/bodyArea.model";
import InteractiveImageModal from "../../components/InteractiveImageModal/InteractiveImageModal";
import AddRelativeVideo from "../../components/AddRelativeVideo/AddRelativeVideo";
import { AssociatedVideoProps } from "../../models/relativevideo.model";
import RelativeMediaCard from "../../components/RelativeMediaCard/RelativeMediaCard";
import ConfirmModal from "../../components/shared/ConfirmModal/ConfirmModal";
import { CTableHead } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { cilBlurCircular } from "@coreui/icons";
import {
  ToastContent,
  getPageContent,
  languages,
} from "../../utils/editor.utils";

const initialValue = {
  actions: "",
  classification: "",
  clinical_application: "",
  combinations: "",
  commentary: "",
  indications: "",
  location_note: "",
  location: "",
  needling: "",
};

const initialHeader: PointDataProps = {
  actions: "Actions",
  classification: "Classification",
  clinical_application: "Clinical Application",
  combinations: "Combinations",
  commentary: "Commentary",
  indications: "Indications",
  location_note: "Location Note",
  location: "Location",
  needling: "Needling",
};

const staticPageInitVal = {
  en: {
    title: "",
    pagecontent: _.cloneDeep(initialValue),
  },
  de: {
    title: "",
    pagecontent: _.cloneDeep(initialValue),
  },
  es: {
    title: "",
    pagecontent: _.cloneDeep(initialValue),
  },
  ru: {
    title: "",
    pagecontent: _.cloneDeep(initialValue),
  },
};

const Points: React.FC = () => {
  const [activeMedia, setActiveMedia] = useState<MediaObject | null>();
  const [allMediaData, setAllMediaData] =
    useState<MediaOriginalDataProps | null>();
  const [confirmModal, setConfirmModal] = useState(false);
  const deleteRelativeVideoData = useRef<MediaObject | null | any>(null);
  const [errors, setErrors] = useState<ErrorPointProps>({});
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenToaster, setIsOpenToaster] = useState(false);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [pageContent, setAllPageContent] = useState<string[]>([]);
  const [pointImageFlag, setPointImageFlag] = useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState("en");
  const [staticPage, setStaticPage] = useState<PageDataType>(
    _.cloneDeep(staticPageInitVal)
  );
  const [textMessage, setTextMessage] = useState("");
  const toaster = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const params = useParams<{ uuid: string }>();
  useEffect(() => {
    saveChannelPoint();
    fetchAllMediaType();
  }, []);

  useEffect(() => {
    if (isOpenToaster) {
      ToastContent(isOpenToaster, errors);
      setIsOpenToaster(false);
    }
  }, [errors]);

  const closeVideoModal = () => {
    setIsVideoModalOpen(false);
    setActiveMedia(null);
  };

  const deleteRelativeVideo = (videoData: MediaObject | null) => {
    const updatedAssociatedVideos: Array<any> = staticPage[
      selectedLanguage
    ].associated_videos.filter(
      (video: MediaObject) => video?.media_id !== videoData!.media_id
    );

    staticPage[selectedLanguage].associated_videos = updatedAssociatedVideos;
    setStaticPage(staticPage);
  };

  const fetchAllMediaType = async () => {
    const [video, audio, image, chineseImage, associatedVideo] =
      await Promise.all([
        getMediaType("point", MEDIA_TYPES.VIDEO),
        getMediaType("point", MEDIA_TYPES.AUDIO),
        getMediaType("point", MEDIA_TYPES.IMAGE),
        getMediaType("point", MEDIA_TYPES.CHINESE_IMAGE),
        getMediaType("point", MEDIA_TYPES.ASSOCIATED_VIDEO),
      ]);

    setAllMediaData({
      audio: audio.data,
      image: image.data,
      video: video.data,
      associatedVideo: associatedVideo.data,
      chineseImage: chineseImage.data,
    });
  };

  const handleCancel = () => {
    navigate(COMMON_CONSTANTS.PREVIOUS_PAGE);
    setTextMessage("");
  };

  const handleDelete = (media: MediaObject) => {
    deleteRelativeVideoData.current = _.cloneDeep(media);
    setConfirmModal(true);
  };

  const handleChineseCharacter = (
    event: React.ChangeEvent<HTMLInputElement>,
    language: keyof StaticPageState
  ) => {
    const newTitle = event.target.value;

    setStaticPage((prevState: PageDataType) => ({
      ...prevState,
      chinese_character: newTitle,
    }));
  };

  const handleContentChange: UpdateContentFunction = (
    htmlContent: string,
    key: string
  ) => {
    setStaticPage((prevStaticPage: PageDataType) => {
      const staticPageContent: PageDataType = { ...prevStaticPage };
      if (
        staticPageContent[selectedLanguage].pagecontent &&
        typeof staticPageContent[selectedLanguage].pagecontent[key] !==
          "undefined"
      ) {
        staticPageContent[selectedLanguage].pagecontent[key] = htmlContent;
      } else {
        staticPageContent[selectedLanguage].pagecontent = initialValue;
        staticPageContent[selectedLanguage].pagecontent[key] = htmlContent;
      }

      return staticPageContent;
    });
  };

  const handleEnglishTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    language: keyof StaticPageState
  ) => {
    const newTitle = event.target.value;

    setStaticPage((prevState: PageDataType) => ({
      ...prevState,
      english_title:
        selectedLanguage !== "en" ? staticPage?.english_title : newTitle,
      [language]: {
        ...prevState[language],
        point_title: newTitle,
      },
    }));
  };

  const handleRelVideoAdd = (relativeMedia: MediaObject): void => {
    const _tempObject = _.cloneDeep(staticPage);
    const relative_videos = _tempObject[selectedLanguage]?.associated_videos;
    relative_videos.push(relativeMedia);
    _tempObject[selectedLanguage].associated_videos = relative_videos;
    setStaticPage(_tempObject);
  };

  const handleSave = () => {
    setIsLoading(true);
    const data: PageDataType = _.cloneDeep(staticPage);
    const allErrors: Content = {};
    languages.map((lang: Language) => {
      data[lang.code] = { ...data[lang.code].pagecontent };
      data[lang.code].video = staticPage[lang.code]?.video?.[0] ?? {};
      data[lang.code].audio = staticPage[lang.code]?.audio?.[0] ?? {};
      data[lang.code].image = staticPage[lang.code]?.image?.[0] ?? {};
      data[lang.code].point_title = staticPage[lang.code]?.point_title ?? "";
      data[lang.code].associated_videos =
        staticPage[lang.code]?.associated_videos.map((item: any) => {
          const ids = item?.subscription_id ?? [];
          !ids.includes(COMMON_CONSTANTS.VIDEO_PERMISSION_ALL) &&
            ids.push(COMMON_CONSTANTS.VIDEO_PERMISSION_ALL);
          return {
            media_id: item?.media_id,
            subscription_id: ids,
          };
        }) || [];
      if (staticPage[lang.code]?.title)
        data[lang.code].title = staticPage[lang.code]?.title ?? "";
      const test = getPageContent(data[lang.code]);
      if (
        test &&
        (data[lang.code].title === "" || data[lang.code].title === undefined)
      ) {
        allErrors[lang.name] = "Please enter a title";
      }

      if (
        test &&
        (data[lang.code].point_title === "" ||
          data[lang.code].point_title === undefined)
      ) {
        allErrors[lang.name] = "Please enter a English title";
      }
    });

    if (Object.keys(allErrors).length > 0) {
      setErrors(allErrors);
      setIsOpenToaster(true);
      setIsLoading(false);
    } else {
      setIsOpenToaster(false);
      const payload = {
        chinese_character: data.chinese_character,
        chinese_image_id: data.chinese_image_id?.media_id,
        english_title: data.english_title,
        locales: { ...data },
        point_id: params.uuid,
      };

      API_SERVICES.POINT.SAVE_CHANNEL_POINT(payload).then(data => {
        const responseData = handleResponse(data);
        if (responseData.ok) {
          setPointImageFlag(true);
          commonService.toastService(
            responseData.data,
            CONSTANTS.STATUS.SUCCESS
          );
        } else {
          commonService.toastService(
            "Failed to update channel point",
            CONSTANTS.STATUS.FAILED
          );
        }
        setIsLoading(false);
      });
    }
  };

  const handleTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    language: keyof StaticPageState
  ) => {
    const newTitle = event.target.value;
    setStaticPage((prevState: PageDataType) => ({
      ...prevState,
      [language]: {
        ...prevState[language],
        title: newTitle,
      },
    }));
  };

  const openVideoModal = (selectedActiveMedia: MediaObject | undefined) => {
    setIsVideoModalOpen(true);
    setActiveMedia(selectedActiveMedia);
  };

  //  TODO Reorder the methods alphabetically
  const saveChannelPoint = async () => {
    const { data } = await getChannelPointPage(params?.uuid as string);
    setPointImageFlag(true);
    setStaticPage((prevState: PageDataType) => ({
      ...prevState,
      ...data,
    }));
    if (data.en.pagecontent) {
      setAllPageContent(Object.keys(data.en.pagecontent));
    }
  };

  const updateFlag = () => {
    saveChannelPoint();
  };

  const updateStaticPageMedia = (data: MediaObject, mediaType: string) => {
    setPointImageFlag(false);
    setStaticPage((prevStaticPage: PageDataType) => {
      const list: PageDataType = _.cloneDeep(prevStaticPage);

      if (!list[selectedLanguage]) {
        list[selectedLanguage] = {};
      }

      if (
        mediaType === MEDIA_TYPES.IMAGE ||
        mediaType === MEDIA_TYPES.VIDEO ||
        mediaType === MEDIA_TYPES.AUDIO ||
        mediaType === MEDIA_TYPES.ASSOCIATED_VIDEO
      ) {
        if (
          list[selectedLanguage][mediaType] &&
          list[selectedLanguage][mediaType][0]
        ) {
          data.point_media_id =
            list[selectedLanguage][mediaType][0].point_media_id;
          list[selectedLanguage][mediaType] = [data];
        } else {
          list[selectedLanguage][mediaType] = [
            { ...data, point_media_id: null },
          ];
        }
      } else if (mediaType === MEDIA_TYPES.CHINESE_IMAGE) {
        list.chinese_image_id = data;
      }

      return list;
    });
  };

  const updatedPermission = (
    id: number,
    flag: boolean,
    media_id: number,
    label: string
  ) => {
    const staticPageContent = _.cloneDeep(staticPage);
    const updatedIndex = _.findIndex(
      staticPageContent[selectedLanguage].associated_videos,
      (media: AssociatedVideoProps) => media?.media_id === media_id
    );
    const updatedVideo =
      staticPageContent[selectedLanguage].associated_videos[updatedIndex];
    let updatedPermission: number[] = [];
    const currentPermission =
      staticPageContent[selectedLanguage].associated_videos[updatedIndex]
        .subscription_id;
    if (id > COMMON_CONSTANTS.MEDIA_LENGTH) {
      updatedPermission = _.xor(currentPermission, [Number(id)]);
    } else if (
      Number(id) === COMMON_CONSTANTS.MEDIA_LENGTH &&
      currentPermission.length === COMMON_CONSTANTS.VIDEO_PERMISSION_ALL
    ) {
      updatedPermission = [COMMON_CONSTANTS.VIDEO_PERMISSION_ALL];
    } else {
      updatedPermission = [
        COMMON_CONSTANTS.VIDEO_PERMISSION_LITE,
        COMMON_CONSTANTS.VIDEO_PERMISSION_STANDARD,
        COMMON_CONSTANTS.VIDEO_PERMISSION_ALL,
      ];
    }
    staticPageContent[selectedLanguage].associated_videos[
      updatedIndex
    ].subscription_id = updatedPermission;
    setStaticPage(staticPageContent);
  };

  const activeMediaProps = {
    title:
      _.capitalize(activeMedia?.media_type) +
      " - " +
      _.capitalize(activeMedia?.title || ""),
    type: activeMedia?.media_type,
  };
  return (
    <>
      <ConfirmModal
        title=" Are you sure you want to delete relative videos"
        confirmButtonText="Confirm"
        cancelButtonText="Cancel"
        onConfirm={() => {
          setConfirmModal;
          setConfirmModal(false);
          if (
            deleteRelativeVideoData.current &&
            deleteRelativeVideoData.current !== ""
          ) {
            deleteRelativeVideo(deleteRelativeVideoData.current);
          }
        }}
        onCancel={() => {
          deleteRelativeVideoData.current = null;
          setConfirmModal(false);
        }}
        isShow={confirmModal}
      />
      <CNav variant="tabs" role="tablist">
        {languages.map((lang: Language) => (
          <CNavItem role="presentation" key={lang.id}>
            <CNavLink
              active={selectedLanguage === lang.code}
              component="button"
              role="tab"
              aria-controls={`tab-${lang.code}`}
              aria-selected={selectedLanguage === lang.code}
              onClick={() => setSelectedLanguage(lang.code)}
            >
              {lang.name}
            </CNavLink>
          </CNavItem>
        ))}
      </CNav>

      <CFormInput
        className="form-title"
        type="text"
        value={staticPage[selectedLanguage]?.title || ""}
        onChange={e =>
          handleTitleChange(e, selectedLanguage as keyof StaticPageState)
        }
      />
      <CAccordion>
        {pageContent.map((element: string) => {
          return (
            <CAccordionItem key={element + selectedLanguage}>
              <CAccordionHeader>{initialHeader[element]}</CAccordionHeader>
              <CAccordionBody key={element + selectedLanguage}>
                <>
                  <Editor
                    key={element + selectedLanguage}
                    indexKey={element}
                    initialContent={
                      staticPage[selectedLanguage]?.pagecontent?.[element] || ""
                    }
                    updateContent={handleContentChange}
                  />
                </>
              </CAccordionBody>
            </CAccordionItem>
          );
        })}
      </CAccordion>

      {
        <>
          <CRow className="mt-3">
            <CCol sm={6}>
              <MediaCard
                key={"videp" + uniqueId}
                mediaData={allMediaData?.video}
                cardTitle="Video"
                type="video"
                mediaArray={staticPage[selectedLanguage].video}
                onClickImage={openVideoModal}
                updateData={(data: MediaObject) => {
                  updateStaticPageMedia(data, "video");
                }}
                playButtonOverlay={true}
              />
            </CCol>

            <CCol sm={6}>
              <MediaCard
                mediaData={allMediaData?.audio}
                cardTitle="Audio"
                type="audio"
                mediaArray={staticPage[selectedLanguage]?.audio}
                updateData={(data: MediaObject) => {
                  updateStaticPageMedia(data, "audio");
                }}
              />
            </CCol>
          </CRow>
          <CRow className="mt-3">
            <CCol sm={6}>
              <MediaCard
                key={selectedLanguage}
                mediaData={allMediaData?.image}
                cardTitle="Image"
                type="image"
                mediaArray={staticPage[selectedLanguage]?.image}
                onClickImage={openVideoModal}
                updateData={(data: MediaObject) => {
                  updateStaticPageMedia(data, "image");
                }}
              />
            </CCol>
            <CCol sm={6}>
              <MediaCard
                key={selectedLanguage}
                mediaData={allMediaData?.chineseImage}
                cardTitle="Chinese Image"
                type="chinese_image"
                mediaArray={[staticPage?.chinese_image_id || {}]}
                onClickImage={openVideoModal}
                updateData={(data: MediaObject) => {
                  updateStaticPageMedia(data, "chinese_image");
                }}
              />
            </CCol>
          </CRow>
          <CRow className="mt-3">
            <CCol sm={6}>
              <CCard>
                <CCardBody>
                  <CCardTitle>Related Videos</CCardTitle>
                  <AddRelativeVideo
                    selectedVideoId={
                      staticPage[selectedLanguage].video
                        ? staticPage[selectedLanguage].video[0]?.media_id
                        : 0
                    }
                    relative_videos={allMediaData?.video || []}
                    key={staticPage[selectedLanguage]?.video}
                    onVideoAdd={handleRelVideoAdd}
                    activeRelativeVideo={
                      staticPage[selectedLanguage].associated_videos || []
                    }
                  />

                  <CTable striped>
                    <CTableHead>
                      <CTableRow>
                        <CTableHeaderCell scope="col">Video</CTableHeaderCell>
                        <CTableHeaderCell scope="col">
                          Permissions
                        </CTableHeaderCell>
                        <CTableHeaderCell>
                          <CIcon icon={cilBlurCircular} size="xl" />
                        </CTableHeaderCell>
                      </CTableRow>
                    </CTableHead>
                    <CTableBody>
                      {staticPage[selectedLanguage].associated_videos?.map(
                        (video: AssociatedVideoProps, index: number) => (
                          <RelativeMediaCard
                            key={index}
                            media={video}
                            onClickImage={openVideoModal}
                            onDelete={handleDelete}
                            updatedPermission={updatedPermission}
                            selectedItemsId={itemId => {}}
                          />
                        )
                      )}
                    </CTableBody>
                  </CTable>
                </CCardBody>
              </CCard>
            </CCol>
            <CCol sm={6}>
              <CCard>
                <CCardBody>
                  <CCardTitle className="mx-2">Other Details</CCardTitle>
                  <div className=" row align-items-center mt-2">
                    <CFormLabel
                      className="col-5 text-end align-center"
                      htmlFor="inputNewPassword"
                    >
                      Title {<span className="text-danger">*</span>}
                    </CFormLabel>
                    <div className="col-7 ">
                      <CFormInput
                        type="text"
                        value={staticPage[selectedLanguage]?.point_title || ""}
                        onChange={e =>
                          handleEnglishTitleChange(
                            e,
                            selectedLanguage as keyof StaticPageState
                          )
                        }
                      />
                    </div>
                  </div>
                  <CCol>
                    <div className=" row align-items-center">
                      <CFormLabel
                        className="col-5 text-end align-center"
                        htmlFor="inputNewPassword"
                      >
                        Character {<span className="text-danger">*</span>}
                      </CFormLabel>
                      <div className="col-7">
                        <CFormInput
                          className="mt-2"
                          type="text"
                          value={staticPage?.chinese_character || ""}
                          onChange={e =>
                            handleChineseCharacter(
                              e,
                              selectedLanguage as keyof StaticPageState
                            )
                          }
                        />
                      </div>
                    </div>
                  </CCol>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </>
      }

      <div className="mp-button-container">
        <CButton className="me-2" color="secondary" onClick={handleCancel}>
          Cancel
        </CButton>
        <CButton color="danger" onClick={handleSave}>
          Save
        </CButton>
      </div>
      <Modal
        className="modal-static"
        onSave={handleSave}
        activeMediaProps={activeMediaProps}
        onClose={closeVideoModal}
        open={isVideoModalOpen}
        showSave={false}
      >
        {activeMedia?.media_type === MEDIA_TYPES.VIDEO ? (
          <video style={{ width: "100%" }} controls>
            <source src={activeMedia?.file_name} type="video/mp4" />
          </video>
        ) : activeMedia?.media_type !== POINT_CONSTANTS.CHINESE_IMAGE ? (
          <InteractiveImageModal
            interactiveImagePoints={staticPage[selectedLanguage]?.point_mapping}
            interactiveImage={activeMedia}
            pointImageFlag={pointImageFlag}
            updateFlag={updateFlag}
          ></InteractiveImageModal>
        ) : (
          <CCardImage orientation="top" src={activeMedia?.file_name} />
        )}
      </Modal>

      <PageLoader isLoading={isLoading} overlayOnly={false} />
    </>
  );
};

export default Points;
