import React, { useEffect, useState } from "react";

import {
  CCard,
  CCardBody,
  CCardImage,
  CCardTitle,
  CFormSelect,
  CInputGroup,
} from "@coreui/react";
import _ from "lodash";
import { VideoCardProps, VideoObject } from "../../models/channel.model";

const VideoCard: React.FC<VideoCardProps> = ({
  mediaData,
  type,
  mediaArray = [],
  cardTitle = "",
  onClickImage,
  updateData,
  onMediaChange,
}) => {
  const [activeMedia, setActiveMedia] = useState<VideoObject | undefined>(
    _.size(mediaArray) ? mediaArray[0] : undefined
  );
  useEffect(() => {
    setActiveMedia(_.first(mediaArray));
  }, [mediaArray]);

  const handleOnChange = (value: any) => {
    const currentMedia = mediaData?.find(
      media => media?.media_id == value.target.value
    );
    if (currentMedia) {
      onMediaChange?.(currentMedia?.media_id || null);
      setActiveMedia(currentMedia);
      updateData && updateData(currentMedia);
    }
  };

  return (
    <CCard className="h-100">
      <CCardBody>
        <CCardTitle>{cardTitle}</CCardTitle>
        <div>
          <CCardImage
            className="image-card"
            onClick={() => {
              onClickImage && onClickImage(activeMedia as VideoObject);
            }}
            orientation="top"
            src={
              activeMedia?.thumbnail_url ||
              "/assets/images/media-placeholder.png" ||
              ""
            }
          />
          {mediaData && mediaData?.length > 0 && (
            <div>
              <label htmlFor="mediaSelect">Please Select a Video</label>
              <CInputGroup>
                <CFormSelect
                  id="mediaSelect"
                  className="mt-3"
                  onChange={handleOnChange}
                  value={activeMedia?.media_id || ""}
                >
                  <option value="">Please Select </option>
                  {mediaData?.map(media => (
                    <option key={media?.media_id} value={media?.media_id}>
                      {media?.title}
                    </option>
                  ))}
                </CFormSelect>
              </CInputGroup>
            </div>
          )}
        </div>
      </CCardBody>
    </CCard>
  );
};

export default VideoCard;
